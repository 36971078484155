import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Product } from '../order-page.component';
import { ProductsModel } from 'src/app/services/swagger.gen';

interface OrderModalData {
  productName: string;
  variants: Product[];
  prices: number[];
}

export interface OrderedItem {
  product: ProductsModel;
  quantity: number;
  price: number;
}

@Component({
  selector: 'app-order-selector-modal',
  templateUrl: './order-selector-modal.component.html',
})
export class OrderSelectorModalComponent {
  @Input() data!: ProductsModel[];
  @Output() closeModal = new EventEmitter<void>();
  @Output() confirmOrder = new EventEmitter<OrderedItem[]>();

  orderItems: OrderedItem[] = [];
  private quantityMap: Map<string, number> = new Map();
  private priceMap: Map<string, number> = new Map();
  showPriceModal = false;
  selectedVariant: ProductsModel | null = null;
  tempPrice = '0.00';

  getVariantType(productName: string): string {
    if (productName.includes('X4')) return 'Multipack';
    if (productName.includes('X12')) return 'Case';
    if (productName.includes('X24')) return 'Case';
    if (productName.includes('500ml')) return 'Single';
    if (productName.includes('Free')) return 'Free';
    if (productName.includes('Seeding')) return 'Seeding';
    return 'Single';
  }

  getFormattedProductName(productName: string, skuName: string): string {
    let formattedProductName = productName
      .toLowerCase()
      .includes(skuName.toLowerCase())
      ? productName.replace(new RegExp(skuName, 'i'), '').trim()
      : productName;
    if (formattedProductName === '') {
      return 'Single Can';
    }
    return (
      formattedProductName.charAt(0).toUpperCase() +
      formattedProductName.slice(1)
    );
  }

  formatPrice(variant: ProductsModel): string {
    const variantKey = `${variant.fullProductName}`;
    const price = this.priceMap.get(variantKey) || 0;
    return price.toFixed(2);
  }

  findPriceIndex(variant: ProductsModel): number {
    return this.data.findIndex(
      (v) => v.fullProductName === variant.fullProductName
    );
  }

  addItemToCart(variant: ProductsModel) {
    const variantKey = `${variant.fullProductName}`;
    const quantity = this.quantityMap.get(variantKey) || 1;
    const price = this.priceMap.get(variantKey) || 0;

    this.orderItems = this.orderItems.filter(
      (item) => !(item.product.fullProductName === variant.fullProductName)
    );

    this.orderItems.push({
      product: variant,
      quantity: quantity,
      price: price,
    });
  }

  itemInCart(variant: ProductsModel): boolean {
    // is variant in cart?
    return this.orderItems.some(
      (item) => item.product.fullProductName === variant.fullProductName
    );
  }

  close() {
    this.closeModal.emit();
  }

  confirm() {
    this.confirmOrder.emit(this.orderItems);
    this.close();
  }

  handleQuantityChange(value: number, variant: ProductsModel) {
    const variantKey = `${variant.fullProductName}`;
    this.quantityMap.set(variantKey, value);
  }

  getCurrentQuantity(variant: ProductsModel): number {
    const variantKey = `${variant.fullProductName}`;
    return this.quantityMap.get(variantKey) || 1;
  }

  openPriceInput(variant: any): void {
    this.selectedVariant = variant;
    this.tempPrice = variant.price ? this.formatPrice(variant) : '0.00';
    this.showPriceModal = true;
    // Add a small delay to ensure the input is rendered
    setTimeout(() => {
      const input = document.querySelector('#priceInput') as HTMLInputElement;
      if (input) {
        input.select(); // This will select all text, making it easy to overwrite
      }
    }, 100);
  }

  handleNumPad(value: string | number): void {
    // If this is the first digit being entered and the current value is '0.00'
    if (this.tempPrice === '0.00' && value !== '⌫' && value !== '.') {
      this.tempPrice = value.toString();
      return;
    }

    if (value === '⌫') {
      this.tempPrice = this.tempPrice.slice(0, -1);
      if (this.tempPrice === '') this.tempPrice = '0';
    } else if (value === '.') {
      // Only add decimal if one doesn't exist
      if (!this.tempPrice.includes('.')) {
        this.tempPrice += '.';
      }
    } else {
      // Don't allow more than 2 decimal places
      const parts = this.tempPrice.split('.');
      if (parts[1] && parts[1].length >= 2) return;

      this.tempPrice += value.toString();
    }

    // Ensure the value is valid
    if (this.tempPrice === '.') this.tempPrice = '0.';
  }

  cancelPriceEdit(): void {
    this.showPriceModal = false;
    this.selectedVariant = null;
  }

  confirmPrice(): void {
    if (this.selectedVariant) {
      const variantKey = `${this.selectedVariant.fullProductName}`;
      const numValue = parseFloat(this.tempPrice);
      if (!isNaN(numValue) && numValue >= 0) {
        this.priceMap.set(variantKey, numValue);
        // If item is in cart, update its price
        const existingItem = this.orderItems.find(
          (item) =>
            item.product.fullProductName ===
            this.selectedVariant?.fullProductName
        );
        if (existingItem) {
          existingItem.price = numValue;
        }
      }
    }
    this.showPriceModal = false;
    this.selectedVariant = null;
  }

  getImagePath(logoFilename: string): string {
    if (!logoFilename) return '';
    return '../assets/images/root-product/' + logoFilename.replace(/\\/g, '/');
  }
}
