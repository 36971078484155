<app-loading-spinner [isLoading]="isLoading"></app-loading-spinner>

<div
  *ngIf="!isLoading && surveyData"
  class="container mx-auto mt-2 px-4 bg-white dark:bg-gray-800 rounded-lg shadow-md pb-36"
>
  <div *ngFor="let step of surveyData.surveySteps; let stepIndex = index">
    <div *ngIf="currentStep === stepIndex" class="survey-step">
      <app-distribution-repeater
        *ngIf="step.surveyStep?.componentName === 'DistributionRepeater'"
        [name]="step.surveyStep?.groupName!"
        (nextSurveyStepEvent)="nextStep()"
        (previousSurveyStepEvent)="previousStep()"
        [questions]="step.questions || []"
        [locationResponses]="locationResponses"
      ></app-distribution-repeater>
      <app-order-page
        *ngIf="step.surveyStep?.componentName === 'OrdersComponent'"
        (nextSurveyStepEvent)="nextStep()"
        (previousSurveyStepEvent)="previousStep()"
        [orderResponse]="getOrderResponse(stepIndex)"
        (orderResponseChange)="updateOrderResponse($event)"
      ></app-order-page>
      <div
        *ngIf="
          step.surveyStep?.componentName !== 'DistributionRepeater' &&
          step.surveyStep?.componentName !== 'OrdersComponent'
        "
      >
        <h2
          class="text-5xl font-teko font-bold text-black dark:text-white font-weight-700 p-8 uppercase text-center"
        >
          {{ step.surveyStep?.groupName }}
        </h2>
        <div class="flex flex-wrap -mx-2">
          <ng-container *ngFor="let question of step.questions">
            <!-- Photo Upload component -->
            <div
              *ngIf="
                getQuestionComponent(question.questionTemplate) ===
                'Photo Upload'
              "
              class="w-full px-2 mb-6"
            >
              <app-photo-upload
                [label]="question.question!"
                [coverImageSelector]="true"
                [id]="'question-' + question.questionsId"
                class="w-full"
                (valueChange)="updateSurveyAnswer(question, $event)"
                [value]="getSurveyAnswer(question)"
              ></app-photo-upload>
            </div>

            <!-- Other question components -->
            <div
              *ngIf="
                getQuestionComponent(question.questionTemplate) !==
                'Photo Upload'
              "
              class="w-full sm:w-1/2 md:w-1/3 px-2 mb-6"
            >
              <ng-container
                [ngSwitch]="getQuestionComponent(question.questionTemplate)"
              >
                <app-text-field
                  *ngSwitchCase="'Text Field'"
                  [label]="question.question!"
                  [id]="'question-' + question.questionsId"
                  (valueChange)="updateSurveyAnswer(question, $event)"
                  [value]="getSurveyAnswer(question)"
                  class="w-full"
                ></app-text-field>
                <app-dropdown
                  *ngSwitchCase="'Dropdown'"
                  [label]="question.question!"
                  [id]="'question-' + question.questionsId"
                  [options]="getDropdownOptions(question)"
                  (valueChange)="updateSurveyAnswer(question, $event)"
                  [value]="getSurveyAnswer(question)"
                  class="w-full"
                ></app-dropdown>
                <app-number-input
                  *ngSwitchCase="'Number Input'"
                  [label]="question.question!"
                  [id]="'question-' + question.questionsId"
                  class="w-full"
                  (valueChange)="updateSurveyAnswer(question, $event)"
                  [value]="getSurveyAnswer(question)"
                ></app-number-input>
                <app-dropdown-multi
                  *ngSwitchCase="'MultiSelectDropDown'"
                  [label]="question.question!"
                  [id]="'question-' + question.questionsId"
                  [options]="getDropdownOptions(question)"
                  class="w-full"
                  (valueChange)="updateSurveyAnswer(question, $event)"
                  [value]="getSurveyAnswer(question)"
                ></app-dropdown-multi>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </div>
    </div>

    <div *ngIf="!isLoading && !surveyData" class="text-center mt-8">
      <p class="text-xl font-semibold text-gray-600 dark:text-gray-400">
        No survey data available.
      </p>
    </div>
  </div>

  <!-- show if current step is not distribution repeater -->
  <app-bottom-nav-bar
    [show]="!hideNavBar(currentStep)"
    [leftButtonText]="'Back'"
    [rightButtonText]="'Next'"
    [leftButtonIcon]="'fa-solid fa-arrow-left'"
    [rightButtonIcon]="'fa-solid fa-arrow-right'"
    (leftButtonClickEvent)="previousStep()"
    (rightButtonClickEvent)="nextStep()"
  ></app-bottom-nav-bar>
</div>
