import {
  Component,
  Input,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  forwardRef,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { DocumentsService } from '../../../services/swagger.gen';
import { AppSettings } from '../../../common/appSettings';
import { HttpClient } from '@angular/common/http';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-photo-upload',
  templateUrl: './photo-upload.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhotoUploadComponent),
      multi: true,
    },
  ],
})
export class PhotoUploadComponent implements ControlValueAccessor, OnChanges {
  @Input() label?: string;
  @Input() coverImageSelector: boolean = false;
  @Input() maxFiles: number = 3;
  @Input() value: string[] | string | number | null = null;

  @Output() valueChange = new EventEmitter<PhotoUploadOutputModel>();

  selectedFiles: { file: File; url: string }[] = [];
  uploadedFiles: any[] = [];
  coverImageIndex: number = 0;

  private onChange: (value: string[]) => void = () => {};
  private onTouched: () => void = () => {};

  constructor(
    private cdr: ChangeDetectorRef,
    private documentsService: DocumentsService,
    public appSettings: AppSettings,
    private http: HttpClient
  ) {}

  onFileSelected(event: any): void {
    const files: FileList = event.target.files;
    const newFiles = Array.from(files).map((file) => ({
      file,
      url: URL.createObjectURL(file),
    }));
    this.selectedFiles = [...this.selectedFiles, ...newFiles];

    if (this.selectedFiles.length === newFiles.length) {
      this.coverImageIndex = 0;
    }

    this.cdr.detectChanges();

    setTimeout(() => {
      this.scrollToBottom();
    }, 100);

    // Convert all files to base64 and emit array
    Promise.all(
      this.selectedFiles.map((fileObj) => this.fileToBase64(fileObj.file))
    ).then((base64Array) => {
      this.onChange(base64Array);
      this.valueChange.emit({
        photos: base64Array,
        coverImageIndex: this.coverImageIndex,
      });
    });
  }

  private fileToBase64(file: File): Promise<string> {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as string);
      reader.readAsDataURL(file);
    });
  }

  removeFile(index: number): void {
    const fileObj = this.selectedFiles[index];
    URL.revokeObjectURL(fileObj.url);
    this.selectedFiles.splice(index, 1);

    // Add this to emit updated values after removal
    Promise.all(
      this.selectedFiles.map((fileObj) => this.fileToBase64(fileObj.file))
    ).then((base64Array) => {
      this.onChange(base64Array);
      this.valueChange.emit({
        photos: base64Array,
        coverImageIndex: this.coverImageIndex,
      });
    });

    if (index <= this.coverImageIndex) {
      this.coverImageIndex = Math.min(
        this.coverImageIndex,
        this.selectedFiles.length - 1
      );
    }

    this.cdr.detectChanges();
  }

  setCoverImage(index: number): void {
    this.coverImageIndex = index;
    this.valueChange.emit({
      photos: this.selectedFiles.map((file) => file.url),
      coverImageIndex: this.coverImageIndex,
    });
  }

  ngOnDestroy() {
    this.selectedFiles.forEach((fileObj) => URL.revokeObjectURL(fileObj.url));
  }

  @ViewChild('fileList') fileListElement!: ElementRef;

  private scrollToBottom(): void {
    if (this.fileListElement) {
      const element = this.fileListElement.nativeElement;
      const rect = element.getBoundingClientRect();
      const scrollPosition = window.pageYOffset + rect.bottom + 200;

      window.scrollTo({
        top: scrollPosition,
        behavior: 'smooth',
      });
    }
  }

  writeValue(value: PhotoUploadOutputModel | null): void {
    if (!value || !value.photos) {
      this.selectedFiles = [];
      return;
    }

    // Reconstruct file objects from base64 strings
    this.selectedFiles = value.photos.map((base64String, index) => ({
      file: this.base64ToFile(base64String, `photo-${index}.jpg`),
      url: base64String,
    }));

    this.coverImageIndex = value.coverImageIndex;
  }

  private base64ToFile(base64String: string, filename: string): File {
    const arr = base64String.split(',');
    const mime = arr[0].match(/:(.*?);/)?.[1] || 'image/jpeg';
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  setDisabledState?(isDisabled: boolean): void {
    throw new Error('Method not implemented.');
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['value'] && changes['value'].currentValue) {
      this.writeValue(changes['value'].currentValue);
    }
  }
}

export interface PhotoUploadOutputModel {
  photos: string[];
  coverImageIndex: number;
}
