import {
  Component,
  Renderer2,
  Inject,
  Output,
  EventEmitter,
  HostListener,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DarkModeService } from '../../services/dark-mode.service';
import { MsalService } from '@azure/msal-angular';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent {
  darkMode: boolean = false;
  darkModeSubscription: Subscription = new Subscription();
  profileImageUrl: string | ArrayBuffer | null = null;
  showDropdown: boolean = false;

  constructor(
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private darkModeService: DarkModeService,
    private router: Router,
    private msalService: MsalService,
    private http: HttpClient
  ) { }

  ngOnInit() {
    this.getUserProfileImage();
    this.darkModeSubscription = this.darkModeService.currentDarkMode.subscribe(
      (darkMode) => {
        this.darkMode = darkMode;
        if (darkMode) {
          this.renderer.addClass(this.document.body, 'dark');
        } else {
          this.renderer.removeClass(this.document.body, 'dark');
        }
      }
    );
  }

  ngOnDestroy() {
    this.darkModeSubscription.unsubscribe();
  }

  toggleDarkMode() {
    this.darkModeService.toggleDarkMode();
  }

  navigateToRoot() {
    this.router.navigate(['/']);
  }

  leftSidebar = false;
  notificationSidebar = false;

  @Output() close = new EventEmitter();

  onClose() {
    this.close.emit();
  }

  private getUserProfileImage(): void {
    this.profileImageUrl = 'assets/Claw-Icon.png';
    const account = this.msalService.instance.getActiveAccount();

    if (account) {
      try {
        this.msalService.instance.acquireTokenSilent({
          scopes: ['User.Read'],
          account: account,
        })
          .then((response) => {
            this.http
              .get('https://graph.microsoft.com/v1.0/me/photo/$value', {
                headers: {
                  Authorization: `Bearer ${response.accessToken}`,
                },
                responseType: 'blob',
              })
              .subscribe({
                next: (blob: Blob) => {
                  const reader = new FileReader();
                  reader.onload = () => {
                    this.profileImageUrl = reader.result;
                  };
                  reader.readAsDataURL(blob);
                },
                error: (error) => {
                  console.log("no photo - GET error'd");
                },
              });
          });
      }
      catch {
        console.log("no photo - GET threw/throw'd");
      }
    }
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const profileImage = event.target as HTMLElement;
    const isProfileImageClick = profileImage.closest(
      'img[src="' + this.profileImageUrl + '"]'
    );

    if (!isProfileImageClick && this.showDropdown) {
      this.showDropdown = false;
    }
  }

  toggleDropdown() {
    event?.stopPropagation();
    this.showDropdown = !this.showDropdown;
  }

  logout() {
    this.msalService.logout();
  }
}
