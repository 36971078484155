import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import Swal from 'sweetalert2';
import { Subject, takeUntil, forkJoin } from 'rxjs';
import { ToastService } from '../_shared/services/toast.service';
import {
  TradeDevelopmentManagersService,
  StoresListRequestModel,
  FavouritesService,
  StoresListUIModel,
  TradeDevelopmentManagersModel,
  StoresListService,
  FavouritesModel,
} from '../services/swagger.gen';
import * as dayjs from 'dayjs';
import * as quarterOfYear from 'dayjs/plugin/quarterOfYear';
import * as isBetween from 'dayjs/plugin/isBetween';
import { RemoveIconPipe } from '../call-list/remove-icon.pipe';
import { Router } from '@angular/router';
import { IdentityService } from '../_shared/services/identity.service';

dayjs.extend(quarterOfYear);
dayjs.extend(isBetween);

@Component({
  selector: 'app-call-list',
  templateUrl: './call-list.component.html',
  styleUrls: ['./call-list.component.css'],
  providers: [RemoveIconPipe],
})
export class CallListComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  isLoading = true;

  activeFilter: CallListFilter = CallListFilter.All;

  originalStoreData: StoresListUIModel[] = [];
  storeCallList: CallListStore[] = [];
  filteredCallList: CallListStore[] = [];
  storeCallListVisible: CallListStore[] = [];

  showTdms = true;
  tdmList: TradeDevelopmentManagersModel[] = [];
  selectedTdm!: number;

  removedStores: number[] = [];
  removalReasons: Map<number, string> = new Map();

  sortColumn: keyof CallListStore | '' = 'storeName';
  sortDirection: 'asc' | 'desc' = 'asc';

  searchText: string = '';

  pageSize = 10;
  currentPage = 1;
  totalStores = 0;
  totalPages = 0;

  storeCallStatus = StoreCallStatus;
  Math = Math;

  filters = [
    { filter: CallListFilter.All, label: 'All' },
    { filter: CallListFilter.Completed, label: 'Completed' },
    { filter: CallListFilter.Uncompleted, label: 'Uncompleted' },
    { filter: CallListFilter.Favourite, label: 'Favourite' },
    { filter: CallListFilter.Removed, label: 'Removed' },
  ];

  @Output() storeClickEvent = new EventEmitter<number>();
  @Output() addClickEvent = new EventEmitter();

  constructor(
    private toastService: ToastService,
    private tdmService: TradeDevelopmentManagersService,
    private storeListService: StoresListService,
    private favouritesService: FavouritesService,
    private router: Router,
    private identityService: IdentityService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    if (this.identityService.getUserId().length > 0) {
      this.loadStores();
    }

    this.loadInitialData(); //  TODO: remove
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  loadInitialData() {
    this.loadStores();
  }

  loadStores() {
    let requestModel = StoresListRequestModel.fromJS({
      tradeDevelopmentManagersId: Number(localStorage.getItem('tdmId')) || 0,
      tradeDevelopmentManagersUId: this.identityService.getUserId(),
    });

    this.storeListService
      .getForTDM(requestModel)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response) => {
          this.originalStoreData = response;
          this.storeCallList = response.map((store) => ({
            storesListId: store.storesListId!,
            storeName: store.storeName!,
            lastVisit: this.storeLastVisit(store.storesListId!) ?? null,
            postcode: store.postcode!,
            isFavourite: store.isFavourite!,
            status: this.storeCompleted(store.storesListId!),
          }));

          // Store the first store's IDs for wholesale orders
          if (response.length > 0) {
            localStorage.setItem(
              'wholesaleStoreId',
              response[0].storesId!.toString()
            );
            localStorage.setItem(
              'wholesaleStoresListId',
              response[0].storesListId!.toString()
            );
          }

          this.filteredCallList = this.storeCallList;
          this.getFavourites();
          this.updateVisibleStores();
        },
        error: (err) => {
          this.toastService.error('Error loading stores');
          console.error('Error loading stores:', err);
        },
        complete: () => {
          this.isLoading = false;
        },
      });
  }

  getFavourites() {
    let favouritesModel = FavouritesModel.fromJS({
      tradeDevelopmentManagersId: Number(localStorage.getItem('tdmId')),
    });

    this.favouritesService
      .getFavourites(favouritesModel)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response) => {
          this.storeCallList.forEach((store) => {
            store.isFavourite = response.some(
              (fav) => fav.storesListId === store.storesListId
            );
          });
        },
        error: (err) => {
          this.isLoading = false;
          this.toastService.error('Error loading favourites');
          console.error('Error loading favourites:', err);
        },
      });
  }

  updateVisibleStores() {
    const startIndex = (this.currentPage - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.storeCallListVisible = this.filteredCallList.slice(
      startIndex,
      endIndex
    );

    this.totalStores = this.filteredCallList.length;
    this.getTotalPages();
  }

  // onTDMChange(newTDMId: number) {
  //   this.currentTDMId = newTDMId;
  //   this.loadStoresByTDMId(newTDMId);
  // }

  onPageChange(page: number | string) {
    if (typeof page === 'number' && page >= 1 && page <= this.getTotalPages()) {
      this.currentPage = page;
      this.updateVisibleStores();
    }
  }

  storeClick(storeListId: number) {
    let store = this.originalStoreData.find(
      (s) => s.storesListId === storeListId
    );
    if (store) {
      const selectedTdm = this.tdmList.find(
        (tdm) =>
          tdm.tradeDevelopmentManagersId ===
          Number(localStorage.getItem('tdmId'))
      );

      localStorage.setItem('storeListId', store.storesListId!.toString());
      this.router.navigate(['/store-summary', store.storesId]);
    }
  }

  storeLastVisit(storesListId: number): string | null {
    let store = this.originalStoreData.find(
      (s) => s.storesListId === storesListId
    );
    const lastVisit = store?.storeVisits?.[0]?.dateVisited;
    return lastVisit ? lastVisit.format('YYYY-MM-DD') : null;
  }

  storeCompleted(storeId: number): StoreCallStatus {
    const store = this.originalStoreData.find(
      (s) => s.storesListId === storeId
    );
    const lastVisit = this.findLastVisit(storeId);

    if (!lastVisit) {
      return StoreCallStatus.Uncompleted;
    }

    return dayjs(lastVisit).isBetween(
      dayjs().startOf('quarter'),
      dayjs().endOf('quarter'),
      null,
      '[]'
    )
      ? StoreCallStatus.Completed
      : StoreCallStatus.Uncompleted;
  }

  findLastVisit(storeId: number): string | null {
    const store = this.originalStoreData.find(
      (s) => s.storesListId === storeId
    );
    const lastVisit = store?.storeVisits?.reduce<dayjs.Dayjs | null>(
      (latest, visit) => {
        if (!visit.dateVisited) return latest;
        return !latest || visit.dateVisited.isAfter(latest)
          ? visit.dateVisited
          : latest;
      },
      null
    );
    return lastVisit?.format('YYYY-MM-DD') || null;
  }

  toggleFilter(filter: CallListFilter) {
    this.activeFilter = filter;
    this.applyFilters();
  }

  updatePagination() {
    this.totalPages = Math.ceil(
      this.storeCallListVisible.length / this.pageSize
    );

    this.currentPage = Math.max(1, Math.min(this.currentPage, this.totalPages));

    const startIndex = (this.currentPage - 1) * this.pageSize;
    const endIndex = Math.min(
      startIndex + this.pageSize,
      this.storeCallListVisible.length
    );

    this.storeCallListVisible = this.storeCallListVisible.slice(
      startIndex,
      endIndex
    );

    this.totalStores = this.storeCallListVisible.length;
  }

  applyFilters() {
    this.filteredCallList = this.storeCallList.filter((item) => {
      const isRemoved = this.removedStores.includes(item.storesListId);

      switch (this.activeFilter) {
        case CallListFilter.Removed:
          return isRemoved;
        case CallListFilter.Completed:
          return !isRemoved && item.status === StoreCallStatus.Completed;
        case CallListFilter.Uncompleted:
          return !isRemoved && item.status === StoreCallStatus.Uncompleted;
        case CallListFilter.Favourite:
          return !isRemoved && item.isFavourite;
        case CallListFilter.All:
          return !isRemoved;
        default:
          return !isRemoved;
      }
    });

    this.updateVisibleStores();
  }

  getPagesArray(): (number | string)[] {
    const totalPages = this.getTotalPages();
    const currentPage = this.currentPage;
    const pages: (number | string)[] = [];

    if (totalPages <= 7) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      pages.push(1);
      if (currentPage > 4) {
        pages.push('...');
      }
      const startPage = Math.max(2, currentPage - 2);
      const endPage = Math.min(totalPages - 1, currentPage + 2);
      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }
      if (currentPage < totalPages - 3) {
        pages.push('...');
      }
      pages.push(totalPages);
    }

    return pages;
  }

  getTotalPages(): number {
    return Math.ceil(this.totalStores / this.pageSize);
  }

  favouriteStore(storeId: number) {
    const store = this.storeCallListVisible.find(
      (s) => s.storesListId === storeId
    );
    if (store && !store.isFavourite) {
      let favouritesModel = FavouritesModel.fromJS({
        tradeDevelopmentManagersId: Number(localStorage.getItem('tdmId')),
        storesListId: store.storesListId,
      });

      this.favouritesService
        .addFavourite(favouritesModel)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: () => {
            store.isFavourite = true;
          },
          error: (err) => {
            this.toastService.error('Error adding favourite');
            console.error('Error adding favourite:', err);
          },
        });
    }
  }

  unfavouriteStore(storeId: number) {
    const store = this.storeCallListVisible.find(
      (s) => s.storesListId === storeId
    );
    if (store && store.isFavourite) {
      let favouriteModel = FavouritesModel.fromJS({
        tradeDevelopmentManagersId: Number(localStorage.getItem('tdmId')),
        storeListId: store.storesListId,
      });

      this.favouritesService
        .deleteFavourite(favouriteModel)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: () => {
            store.isFavourite = false;
          },
          error: (err) => {
            this.toastService.error('Error removing favourite');
            console.error('Error removing favourite:', err);
          },
        });
    }
  }

  sortTable(column: keyof CallListStore) {
    if (this.sortColumn === column) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortColumn = column;
      this.sortDirection = 'asc';
    }
    this.filteredCallList.sort((a, b) => {
      const valueA = a[column];
      const valueB = b[column];
      if (column === 'lastVisit') {
        const dateA =
          typeof valueA === 'string' ? new Date(valueA) : new Date(0);
        const dateB =
          typeof valueB === 'string' ? new Date(valueB) : new Date(0);
        return this.sortDirection === 'asc'
          ? dateA.getTime() - dateB.getTime()
          : dateB.getTime() - dateA.getTime();
      } else {
        if (valueA! < valueB!) {
          return this.sortDirection === 'asc' ? -1 : 1;
        } else if (valueA! > valueB!) {
          return this.sortDirection === 'asc' ? 1 : -1;
        } else {
          return 0;
        }
      }
    });

    this.updateVisibleStores();
  }

  toggleRemoveStore(storeId: number): void {
    const isRemoved = this.removedStores.includes(storeId);
    const store = this.storeCallList.find((s) => s.storesListId === storeId);

    if (!store) return;

    if (isRemoved) {
      this.removedStores = this.removedStores.filter((id) => id !== storeId);
      this.removalReasons.delete(storeId);
      this.applyFilters();
    } else {
      Swal.fire({
        title: 'Remove Store',
        html: `
          <p>Are you sure you want to remove ${store.storeName} from your call list?</p>
          <input id="removal-reason" class="swal2-input" placeholder="Enter removal reason">
        `,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, remove it!',
        preConfirm: () => {
          const reason = (
            document.getElementById('removal-reason') as HTMLInputElement
          ).value;
          if (!reason) {
            Swal.showValidationMessage('Please enter a removal reason');
          }
          return reason;
        },
      }).then((result) => {
        if (result.isConfirmed) {
          const reason = result.value;
          this.removedStores.push(storeId);
          this.removalReasons.set(storeId, reason);
          this.applyFilters();
          Swal.fire(
            'Removed!',
            `${store.storeName} has been removed from your call list.`,
            'success'
          );
        }
      });
    }
  }

  onSearch(searchText: string) {
    this.currentPage = 1;
    this.filteredCallList = this.storeCallList.filter((item) =>
      item.storeName.toLowerCase().includes(searchText.toLowerCase())
    );

    this.updateVisibleStores();
  }

  clearSearch() {
    this.searchText = '';
    this.onSearch('');
  }

  addNewStore() {
    this.router.navigate(['/add-new-store']);
  }

  // loadInitialDataRFM() {
  //   // Create an array of observables for both API calls
  //   const apiCalls = [
  //     this.tdmService.getForRFM(0),
  //     this.tdmService.getForRFM(1),
  //   ];

  //   // Use forkJoin to execute both API calls concurrently
  //   forkJoin(apiCalls)
  //     .pipe(takeUntil(this.destroy$))
  //     .subscribe({
  //       next: ([response0, response1]) => {
  //         // Combine and filter the results
  //         const allTdms = [...response0, ...response1];
  //         this.tdmList = allTdms
  //           .filter(
  //             (tdm) =>
  //               tdm.tradeDevelopmentManagersId !== undefined &&
  //               tdm.tradeDevelopmentManagersId in this.demoTDMCountryCodes
  //           )
  //           .map((tdm) => ({
  //             ...tdm,
  //             surname: `${tdm.surname} (${
  //               this.demoTDMCountryCodes[
  //                 tdm.tradeDevelopmentManagersId! as keyof typeof this.demoTDMCountryCodes
  //               ]
  //             })`,
  //           })) as TradeDevelopmentManagersModel[];

  //         if (this.tdmList.length > 0) {
  //           this.loadStoresByTDMId(this.currentTDMId);
  //         } else {
  //           console.warn('No matching TDMs found');
  //           this.isLoading = false;
  //         }
  //       },
  //       error: (err) => {
  //         this.isLoading = false;
  //         this.toastService.error('Error loading initial data');
  //         console.error('Error loading initial data:', err);
  //       },
  //       complete: () => {
  //         this.tdmList.sort(
  //           (a, b) =>
  //             (a.tradeDevelopmentManagersId || 0) -
  //             (b.tradeDevelopmentManagersId || 0)
  //         );
  //       },
  //     });
  // }
}

export interface CallListStore {
  storesListId: number;
  storeName: string;
  lastVisit: string | null;
  postcode: string;
  isFavourite: boolean;
  status: StoreCallStatus;
}

export enum StoreCallStatus {
  Uncompleted,
  Completed,
}

export enum CallListFilter {
  All,
  Completed,
  Uncompleted,
  Favourite,
  Removed,
}
