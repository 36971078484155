import {
  Component,
  Output,
  EventEmitter,
  Input,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { NgOptimizedImage } from '@angular/common';
import { CommonModule } from '@angular/common';
import { LocationResponse } from '../../../services/survey-submission.service';
import { ProductsModel, ProductsService } from '../../../services/swagger.gen';
import { ToastService } from 'src/app/_shared/services/toast.service';

@Component({
  selector: 'app-sku-selector',
  templateUrl: './sku-selector.component.html',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage],
})
export class SkuSelectorComponent implements OnInit, OnDestroy {
  products: ProductsModel[] = [];
  // grouped products array of arrays
  groupedProducts: ProductsModel[][] = [];
  visibleGroups: ProductsModel[][] = [];
  private readonly BATCH_SIZE = 3; // Load 3 groups at a time
  private readonly BATCH_DELAY = 300; // 300ms between batches

  @Input()
  response!: LocationResponse;

  constructor(
    private productsService: ProductsService,
    private toast: ToastService
  ) {}

  showScrollButton = false;
  private scrollListener: any;
  private incrementInterval: any;
  private decrementInterval: any;
  private readonly HOLD_DELAY = 500; // 0.5 seconds before rapid increment/decrement
  private readonly RAPID_INTERVAL = 50; // How often (ms) to increment/decrement during rapid mode

  platformGroups: { [key: string]: string } = {
    '2': 'Additions',
    '3': 'Strategic Brands',
    '5': 'Monster',
    '6': 'Juiced',
    '7': 'Affordable',
    '8': 'Performance',
    '9': 'Ultra',
    '10': 'Platform',
    '11': 'Strategic',
    '12': 'Core',
    '13': 'Historic',
    '14': 'Juice',
    '15': 'Ultra Serbia',
    '16': 'Competition',
  };

  ngOnInit() {
    this.productsService
      .getProductsForCountry(localStorage.getItem('countryCode')!)
      .subscribe({
        next: (data) => {
          this.products = data;
          this.groupProducts();
        },
        error: (error) => {
          this.toast.error('Error fetching products');
          console.error(error);
        },
      });

    // Add scroll listener
    this.scrollListener = () => {
      this.showScrollButton = window.scrollY > 100; // Show after 100px of scroll
    };
    window.addEventListener('scroll', this.scrollListener);
  }

  private groupProducts(): void {
    const groupMap = new Map<string, ProductsModel[]>();

    this.products
      .filter((product) => product.packSize === 1)
      .forEach((product) => {
        if (product.logoFilename) {
          const groupName = product.logoFilename.split(/[/\\]/)[0];

          if (groupName === 'MONSTER') {
            // Use the platform dictionary to get the proper name and convert to uppercase
            const platformName = (
              this.platformGroups[product.platformsId?.toString() || 'Other'] ||
              'Other'
            ).toUpperCase();
            const platformGroup = `MONSTER ${platformName}`;
            const group = groupMap.get(platformGroup) || [];
            group.push(product);
            groupMap.set(platformGroup, group);
          } else {
            const group = groupMap.get(groupName) || [];
            group.push(product);
            groupMap.set(groupName, group);
          }
        }
      });

    // Sort each group by shortProductName and then by volume descending
    groupMap.forEach((group) => {
      group.sort((a, b) => {
        const nameCompare = (a.shortProductName || '').localeCompare(
          b.shortProductName || ''
        );
        if (nameCompare !== 0) return nameCompare;
        return (b.volume || 0) - (a.volume || 0);
      });
    });

    // Convert map to arrays but sort Monster groups first
    const sortedGroups = Array.from(groupMap.entries()).sort((a, b) => {
      // If both are Monster groups, sort by platform name
      if (a[0].startsWith('MONSTER') && b[0].startsWith('MONSTER')) {
        return a[0].localeCompare(b[0]);
      }
      // If only a is Monster, it should come first
      if (a[0].startsWith('MONSTER')) return -1;
      // If only b is Monster, it should come first
      if (b[0].startsWith('MONSTER')) return 1;
      // For non-Monster groups, maintain alphabetical order
      return a[0].localeCompare(b[0]);
    });

    // Store only the values (product arrays) in groupedProducts
    this.groupedProducts = sortedGroups.map(([_, products]) => products);
    this.loadImagesProgressively();
  }

  private loadImagesProgressively() {
    let currentIndex = 0;

    const loadNextBatch = () => {
      const batch = this.groupedProducts.slice(
        currentIndex,
        currentIndex + this.BATCH_SIZE
      );

      if (batch.length) {
        this.visibleGroups.push(...batch);
        currentIndex += this.BATCH_SIZE;

        // Preload images for this batch
        batch.forEach((group) => {
          group.forEach((product) => {
            if (product.logoFilename) {
              const img = new Image();
              img.src = this.getImagePath(product.logoFilename);
            }
          });
        });

        setTimeout(loadNextBatch, this.BATCH_DELAY);
      }
    };

    loadNextBatch();
  }

  ngOnDestroy() {
    // Clean up scroll listener
    window.removeEventListener('scroll', this.scrollListener);
  }

  skuTap(id: number): void {
    let amt = this.getSkuAmount(id);
    if (amt == undefined) {
      clearTimeout(this.decrementInterval);
      clearInterval(this.decrementInterval);
      clearTimeout(this.incrementInterval);
      clearInterval(this.incrementInterval);
      this.addSkuAmount(id);
    }
  }

  addSkuAmount(id: number): void {
    let amt = this.getSkuAmount(id);
    if (amt == undefined) {
      this.response.skus.push({
        productId: id.toString(),
        number: 1,
      });
    } else {
      this.response.skus.find((sku) => sku.productId === id.toString())!
        .number++;
    }
  }

  getSkuAmount(id: number): number | undefined {
    if (this.response.skus === undefined) {
      return;
    }
    return this.response.skus.find((sku) => sku.productId === id.toString())
      ?.number;
  }

  removeSkuAmount(id: number): void {
    let amt = this.getSkuAmount(id);
    if (amt == undefined) {
      return;
    } else {
      if (amt == 1) {
        this.response.skus = this.response.skus.filter(
          (sku) => sku.productId !== id.toString()
        );
      } else {
        this.response.skus.find((sku) => sku.productId === id.toString())!
          .number--;
      }
    }
  }

  startIncrement(skuId: number, event: TouchEvent) {
    event.preventDefault(); // Prevent context menu
    this.addSkuAmount(skuId);
    this.incrementInterval = setTimeout(() => {
      this.incrementInterval = setInterval(() => {
        this.addSkuAmount(skuId);
      }, this.RAPID_INTERVAL);
    }, this.HOLD_DELAY);
  }

  stopIncrement() {
    clearTimeout(this.incrementInterval);
    clearInterval(this.incrementInterval);
  }

  startDecrement(skuId: number, event: TouchEvent) {
    event.preventDefault(); // Prevent context menu
    this.removeSkuAmount(skuId);
    this.decrementInterval = setTimeout(() => {
      this.decrementInterval = setInterval(() => {
        this.removeSkuAmount(skuId);
      }, this.RAPID_INTERVAL);
    }, this.HOLD_DELAY);
  }

  stopDecrement() {
    clearTimeout(this.decrementInterval);
    clearInterval(this.decrementInterval);
  }

  // scrollToGroup(group: SkuGroup) {
  //   const groupElement = document.getElementById(group.name);
  //   if (groupElement) {
  //     const headerOffset = 100;
  //     const elementPosition = groupElement.getBoundingClientRect().top;
  //     const offsetPosition = elementPosition + window.scrollY - headerOffset;

  //     window.scrollTo({
  //       top: offsetPosition,
  //       behavior: 'smooth',
  //     });
  //   }
  // }

  scrollToTop(): void {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  skuNumberChange(event$: Event) {
    const currentTarget = event$.currentTarget as any;
    const id = currentTarget.id;
    const value = currentTarget.value;
    let amt = this.getSkuAmount(id);
    if (amt == undefined) {
      this.response.skus.push({
        productId: id.toString(),
        number: Number(value),
      });
    } else {
      this.response.skus.find(
        (sku) => sku.productId === id.toString()
      )!.number = Number(value);
    }
  }

  getProductGroup(logoFilename: string): string {
    if (!logoFilename) return '';
    const groupName = logoFilename.split(/[/\\]/)[0];

    if (groupName === 'MONSTER') {
      // Find the first product in the group to get its platformsId
      const product = this.products.find(
        (p) => p.logoFilename === logoFilename
      );
      if (product && product.platformsId) {
        // Use the platform dictionary to get the proper name
        const platformName = (
          this.platformGroups[product.platformsId.toString()] || 'Other'
        ).toUpperCase();
        return `MONSTER ${platformName}`;
      }
    }

    return groupName;
  }

  getImagePath(logoFilename: string): string {
    if (!logoFilename) return '';
    // Ensure consistent forward slashes for web URLs
    return '../assets/images/root-product/' + logoFilename.replace(/\\/g, '/');
  }
}
