import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import {
  EventMessage,
  EventType,
  AuthenticationResult,
  InteractionStatus,
} from '@azure/msal-browser';
import { environment } from '../../../environments/environment';

export class UserToken {
  public token: string = '';
  public email: string = '';
  public fullName: string = '';
  public roleId: string = '';
}

@Injectable()
export class IdentityService extends UserToken {
  private mCoreRoles: Array<string> = new Array<string>();

  constructor(
    private http: HttpClient,
    private authService: MsalService,
    private broadcastService: MsalBroadcastService
  ) {
    super();
  }

  isAuthenticated(): boolean {
    return this.authService.instance.getAllAccounts().length > 0;
  }

  getUserName() {
    const account = this.authService.instance.getActiveAccount();

    if (account) return account.username;

    return '';
  }

  getUserId(): string {
    const account = this.authService.instance.getActiveAccount();

    if (account)
      return account.homeAccountId.indexOf('.') > -1
        ? account.homeAccountId.substring(0, account.homeAccountId.indexOf('.'))
        : account.homeAccountId;

    return '';
  }

  getFullUserId(): string {
    const account = this.authService.instance.getActiveAccount();

    if (account) return account.homeAccountId;

    return '';
  }

  getMCoreRoles() {
    if (this.mCoreRoles.length == 0) {
    }
  }

  getUserTenant() {
    return localStorage.getItem('current-tenantId')
      ? localStorage.getItem('current-tenantId')
      : '0';
  }

  //getUserRole() {

  //}
  //setUserRole(roleId) {

  //}

  getUserFullName() {
    const account = this.authService.instance.getActiveAccount();
    return account?.name;
  }

  login() {}

  logout(): void {
    localStorage.removeItem('current-tenantId');
    localStorage.removeItem('mnst-role');
    this.authService.logout();
  }

  GetUserRoleId(email: string): Observable<number> {
    const url = environment.API_BASE_URL + '/user/GetUserRoleId?email=' + email; //  TODO: this!

    return this.http.get<number>(url).pipe((res) => {
      const p = res || null;
      return p;
    });
  }

  ADLogin() {
    this.authService.loginRedirect();
  }

  ADPostLoginCallback(): Observable<EventMessage> {
    return this.broadcastService.msalSubject$.pipe(
      filter((msg: EventMessage) => msg.eventType == EventType.LOGIN_SUCCESS),
      tap((msg: EventMessage) => {
        // https://docs.microsoft.com/en-us/azure/active-directory/develop/access-tokens for the various claims in the access token
        // looks like this version of MSAL returns version 2.0 (v2) JSON Web Tokens from Azure
        const payload = msg.payload as AuthenticationResult;
        console.log('ADPostLoginCallback() payload: ' + payload);
        if (!this.authService.instance.getActiveAccount() && payload) {
          this.authService.instance.setActiveAccount(payload.account);
        }
      })
    );
  }

  PostRedirectCallBack(): Observable<InteractionStatus> {
    return this.broadcastService.inProgress$.pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None)
    );
  }

  setActiveAccount(account: any) {
    this.authService.instance.setActiveAccount(account);
  }

  clearStoredData() {
    localStorage.removeItem('userId');
    localStorage.removeItem('tdmId');
    localStorage.removeItem('rfmId');
    localStorage.removeItem('current-tenantId');
    localStorage.removeItem('mnst-role');
  }
}
