import { Component, Input, Output, EventEmitter } from '@angular/core';
import { OrderedItem } from '../order-selector-modal/order-selector-modal.component';

@Component({
  selector: 'app-order-summary',
  templateUrl: './order-summary.component.html',
})
export class OrderSummaryComponent {
  @Input() orderItems: OrderedItem[] = [];
  @Output() orderItemsChange = new EventEmitter<OrderedItem[]>();

  quantityOptions = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24,
  ].map((num) => ({
    label: num.toString(),
    value: num.toString(),
  }));

  getImagePath(logoFilename: string): string {
    if (!logoFilename) return '';
    return '../assets/images/root-product/' + logoFilename.replace(/\\/g, '/');
  }

  removeItem(item: OrderedItem) {
    this.orderItems = this.orderItems.filter((i) => i !== item);
    this.orderItemsChange.emit(this.orderItems);
  }

  getTotal(): number {
    return this.orderItems.reduce(
      (acc, item) => acc + item.price * item.quantity,
      0
    );
  }

  handleQuantityChange(newValue: string, item: OrderedItem) {
    item.quantity = parseInt(newValue);
    this.orderItemsChange.emit(this.orderItems);
  }
}
