import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LocationResponse } from '../../../services/survey-submission.service';

@Component({
  selector: 'app-store-location-card',
  templateUrl: './store-location-card.component.html',
})
export class StoreLocationCardComponent {
  @Input() locationResponse: LocationResponse | null = null;
  @Input() distributionType: 'Entry' | 'Exit' = 'Entry';
  @Input() locationConfirmed: boolean = false;

  lightColor!: 'green-500' | 'orange-500';
  bgColor!: 'green-100' | 'orange-100';
  textColor!: 'green-800' | 'orange-900';

  ngOnInit() {
    if (!this.locationConfirmed) {
      this.lightColor = 'orange-500';
      this.bgColor = 'orange-100';
      this.textColor = 'orange-900';
    } else {
      this.lightColor = 'green-500';
      this.bgColor = 'green-100';
      this.textColor = 'green-800';
    }
  }
}
