import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  SurveysService,
  SurveysUIModel,
  QuestionsUIModel,
  StoresContactsModel,
} from '../../services/swagger.gen';
import Swal from 'sweetalert2';
import { ToastService } from 'src/app/_shared/services/toast.service';
import {
  SurveySubmissionService,
  DropdownOption,
  LocationResponse,
  SurveyStepResponse,
  SurveyResponse,
  OrderResponse,
} from '../../services/survey-submission.service';

@Component({
  selector: 'app-survey-page',
  templateUrl: './survey-page.component.html',
})
export class SurveyPageComponent implements OnInit {
  storeContact: StoresContactsModel | undefined;
  surveyData: SurveysUIModel | undefined;
  currentStep = 0;
  isLoading = true;
  storeId: string = '';
  storesListId: number = 0;
  locationResponses: LocationResponse[] = [];
  surveyStepAnswers: SurveyStepResponse[] = [];

  constructor(
    private surveysService: SurveysService,
    private toast: ToastService,
    private route: ActivatedRoute,
    private router: Router,
    private surveySubmissionService: SurveySubmissionService
  ) {}

  ngOnInit() {
    this.storeId = this.route.snapshot.params['storeId'];
    this.storesListId = Number(localStorage.getItem('storeListId')!);
    this.getSurveyData();
  }

  getSurveyData() {
    this.isLoading = true;
    this.surveysService
      .getSurvey(localStorage.getItem('countryCode') || '')
      .subscribe({
        next: (data) => {
          console.log('Received survey data:', data);
          if (data && data.length > 0) {
            this.surveyData = data[0];
            // order by group order in surveySteps
            if (this.surveyData.surveySteps) {
              this.surveyData.surveySteps = this.surveyData.surveySteps.sort(
                (a, b) => a.surveyStep!.groupOrder! - b.surveyStep!.groupOrder!
              );

              this.surveyStepAnswers = this.surveyData.surveySteps.map(
                (step) => {
                  const componentName = step.surveyStep?.componentName;
                  const isDistributionStep =
                    componentName === 'DistributionRepeater';
                  const isEntryStep = step.surveyStep?.groupName
                    ?.toLowerCase()
                    .includes('entry');

                  return {
                    questionGroupsId: step.surveyStep!.questionGroupsId!,
                    questionGroupsName: step.surveyStep!.groupName!,
                    responses:
                      componentName === 'OrdersComponent'
                        ? ({
                            items: [],
                            isWholesale: false,
                          } as OrderResponse)
                        : isDistributionStep
                        ? this.locationResponses.filter((loc) =>
                            isEntryStep
                              ? !loc.isExitLocation
                              : loc.isExitLocation
                          )
                        : step.questions!.map((question) => ({
                            questionId: question.questionsId!,
                            question: question.question!,
                            response: '',
                          })),
                  };
                }
              );
            }
          } else {
            console.warn('No survey data received');
          }
          this.isLoading = false;
        },
        error: (error) => {
          console.error('Error fetching survey data:', error);
          this.isLoading = false;
        },
        complete: () => {
          console.log('Survey data fetch completed');
        },
      });
  }

  updateOrderResponse(orderResponse: OrderResponse) {
    const currentStepData = this.surveyData?.surveySteps?.[this.currentStep];
    if (currentStepData?.surveyStep?.componentName === 'OrdersComponent') {
      const surveyStepAnswer = this.surveyStepAnswers.find(
        (step) =>
          step.questionGroupsId === currentStepData.surveyStep!.questionGroupsId
      );
      if (surveyStepAnswer) {
        surveyStepAnswer.responses = orderResponse;
      }
    }
  }

  getOrderResponse(stepIndex: number): OrderResponse {
    const responses = this.surveyStepAnswers[stepIndex]?.responses;
    if (responses && !Array.isArray(responses) && 'items' in responses) {
      return responses as OrderResponse;
    }
    return {
      items: [],
      isWholesale: false,
    };
  }

  getQuestionComponent(template: string | undefined): string {
    if (!template) return 'Unknown';
    switch (template.trim()) {
      case 'FreeText':
        return 'Text Field';
      case 'DropdownList':
        return 'Dropdown';
      case 'NumberPlusMinus':
        return 'Number Input';
      case 'PhotoUploader':
        return 'Photo Upload';
      case 'YesNo':
        return 'Dropdown';
      case 'MultiSelectDropdown':
        return 'MultiSelectDropDown';
      default:
        return 'Unknown';
    }
  }

  getDropdownOptions(question: QuestionsUIModel): DropdownOption[] {
    if (question.questionTemplate?.trim() === 'YesNo') {
      return [
        { value: 'Yes', label: 'Yes' },
        { value: 'No', label: 'No' },
      ];
    }
    if (!question.values) return [];
    return Object.entries(question.values).map(([value, label]) => ({
      value,
      label,
    }));
  }

  hideNavBar(step: number): boolean {
    return (
      this.surveyData?.surveySteps?.[step]?.surveyStep?.componentName ===
        'DistributionRepeater' ||
      this.surveyData?.surveySteps?.[step]?.surveyStep?.componentName ===
        'OrdersComponent'
    );
  }

  nextStep() {
    if (this.currentStep < (this.surveyData?.surveySteps?.length || 0) - 1) {
      // If we're leaving a distribution repeater step, save its state
      const currentStepData = this.surveyData?.surveySteps?.[this.currentStep];
      if (
        currentStepData?.surveyStep?.componentName === 'DistributionRepeater'
      ) {
        const surveyStepAnswer = this.surveyStepAnswers.find(
          (step) =>
            step.questionGroupsId ===
            currentStepData.surveyStep!.questionGroupsId
        );
        if (surveyStepAnswer) {
          // Store the entire locationResponses array directly
          surveyStepAnswer.responses = this.locationResponses;
        }
      }

      this.currentStep++;
      return;
    }

    if (this.currentStep + 1 >= this.surveyData?.surveySteps?.length!) {
      this.submitSurvey();
    }
  }

  updateSurveyAnswer(question: QuestionsUIModel, answer: any) {
    const surveyStepAnswer = this.surveyStepAnswers.find(
      (step) => step.questionGroupsId === question.questionGroupsId
    );

    // Handle regular survey responses
    if (question.questionTemplate !== 'DistributionRepeater') {
      const answerToUpdate = (
        surveyStepAnswer?.responses as SurveyResponse[]
      )?.find((a) => a.questionId === question.questionsId);
      if (answerToUpdate) {
        answerToUpdate.response = Array.isArray(answer) ? [...answer] : answer;
      }
    }
    // Distribution repeater responses are handled in nextStep()
  }

  getSurveyAnswer(question: QuestionsUIModel): string | number | string[] {
    const surveyStepAnswer = this.surveyStepAnswers.find(
      (step) => step.questionGroupsId === question.questionGroupsId
    );

    // Only handle regular survey responses here
    if (question.questionTemplate !== 'DistributionRepeater') {
      const answer = (surveyStepAnswer?.responses as SurveyResponse[])?.find(
        (a) => a.questionId === question.questionsId
      );

      if (!answer?.response) {
        if (question.questionTemplate === 'MultiSelectDropdown') return [];
        if (question.questionTemplate === 'PhotoUploader') return [];
        return '';
      }

      return answer.response;
    }

    return '';
  }

  async submitSurvey() {
    this.isLoading = true;
    try {
      await this.surveySubmissionService.submitSurvey(
        this.surveyStepAnswers,
        this.storesListId,
        this.storeId,
        this.surveyData?.survey?.surveysId!
      );
      this.router.navigate(['end-store-visit/', this.storeId]);
    } catch (error) {
      this.isLoading = false;
    }
  }

  previousStep() {
    if (this.currentStep <= 0) {
      Swal.fire({
        title: 'Are you sure you want to leave the survey?',
        text: 'You will lose all progress.',
        icon: 'warning',
        confirmButtonText: '<i class="fas fa-check"></i> Confirm',
        cancelButtonText: 'Close',
        customClass: {
          icon: 'no-border',
          confirmButton: 'btn btn-success bg-custom-green text-black',
          title: 'swal2-title-small',
        },
      }).then((result) => {
        if (result.isConfirmed) {
          this.router.navigate(['store-summary/', this.storeId]);
        }
      });
      return;
    }

    this.currentStep--;
  }
}
