<swal #modalContent [showConfirmButton]="false" [showCloseButton]="true">
  <div *swalPortal="swalTargets.content">
    <h2
      class="text-xl font-bold text-gray-900 mb-4 flex justify-start font-teko"
    >
      Store Notes
    </h2>
    <div class="max-h-[60vh] overflow-y-auto">
      <div
        *ngFor="let note of sortedNotes"
        class="mb-6 last:mb-0 p-4 rounded-lg border border-custom-green text-left"
      >
        <p class="text-sm font-bold text-gray-500 mb-1">
          {{ note.lastUpdated?.toDate() | date : "dd/MM/yyyy" }}
          <ng-container *ngIf="note.updatedBy">
            [{{ note.updatedBy }}]
          </ng-container>
        </p>
        <p class="text-sm text-gray-500 font-weight-400">
          {{ note.note }}
        </p>
      </div>

      <div
        *ngIf="sortedNotes.length === 0"
        class="text-gray-500 text-center py-4"
      >
        No notes available
      </div>
    </div>
  </div>
</swal>
