import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ToastService } from 'src/app/_shared/services/toast.service';
import { environment } from 'src/environments/environment';
import { firstValueFrom } from 'rxjs';
import * as dayjs from 'dayjs';
import {
  SurveysService,
  SurveyInstancesUIRequestModel,
  StoreVisitsService,
  StoreVisitsModel,
  UploadDocumentUIModel,
} from './swagger.gen';

export interface DropdownOption {
  value: string;
  label: string;
}

export interface OrderedItem {
  productId: number;
  productName: string;
  price: number;
  quantity: number;
}

export interface OrderResponse {
  items: OrderedItem[];
  signature?: string;
  photoReceipt?: string;
  isWholesale: boolean;
}

export interface LocationResponse {
  locationId: number;
  photo: string | null;
  compliant: boolean;
  location: string;
  storeLocation: string;
  skus: SkuResponse[];
  completed: boolean;
  createdAt: 'Entry' | 'Exit';
  pairId?: number;
  isExitLocation?: boolean;
}

export interface SkuResponse {
  productId: string;
  number: number;
}

export interface SurveyStepResponse {
  questionGroupsId: number;
  questionGroupsName: string;
  responses: SurveyResponse[] | LocationResponse[] | OrderResponse;
}

export interface SurveyResponse {
  questionId: number;
  question: string;
  response: any;
}

@Injectable({
  providedIn: 'root',
})
export class SurveySubmissionService {
  constructor(
    private surveysService: SurveysService,
    private storesVisitsService: StoreVisitsService,
    private toast: ToastService,
    private http: HttpClient
  ) {}

  async submitSurvey(
    surveyStepAnswers: SurveyStepResponse[],
    storesListId: number,
    storeId: string,
    surveyId: number
  ) {
    const storeVisitId = await this.createStoreVisit(storesListId);
    if (!storeVisitId) {
      throw new Error('Failed to create store visit');
    }

    await this.submitSurveyAnswers(
      surveyStepAnswers,
      storeId,
      storeVisitId,
      surveyId,
      storesListId
    );

    return storeVisitId;
  }

  private async createStoreVisit(storesListId: number): Promise<number> {
    const storeVisitsModel = new StoreVisitsModel();
    storeVisitsModel.storesListId = storesListId;
    storeVisitsModel.tradeDevelopmentManagersId = Number(
      localStorage.getItem('tdmId')!
    );
    storeVisitsModel.surveyCompleted = true;
    storeVisitsModel.updatedBy = localStorage.getItem('userEmail')!;
    storeVisitsModel.lastUpdated = dayjs();
    storeVisitsModel.dateVisited = dayjs();

    let dayType = localStorage.getItem('selectedDayType')!;
    if (dayType === 'bottler') {
      storeVisitsModel.bottlersRepresentativePresent = true;
    }
    if (dayType === 'partner') {
      storeVisitsModel.tradePartnerDay = true;
    }
    if (dayType === 'rfm') {
      storeVisitsModel.regionalManagerPresent = true;
    }

    try {
      const response = await firstValueFrom(
        this.storesVisitsService.writeStoreVisitsRow(storeVisitsModel)
      );
      return response.storeVisitsId!;
    } catch (error) {
      this.toast.error('Error creating store visit');
      throw error;
    }
  }

  private async submitSurveyAnswers(
    surveyStepAnswers: SurveyStepResponse[],
    storeId: string,
    storeVisitId: number,
    surveyId: number,
    storesListId: number
  ) {
    const surveyInstance = new SurveyInstancesUIRequestModel();
    surveyInstance.surveysId = surveyId;
    surveyInstance.storesId = Number(storeId);
    surveyInstance.completed = true;
    surveyInstance.completedBy = localStorage.getItem('userEmail')!;
    surveyInstance.updatedBy = localStorage.getItem('userEmail')!;
    surveyInstance.surveyDate = dayjs();
    surveyInstance.lastUpdated = dayjs();

    // Upload photos before stringifying survey data
    await this.uploadPhotos(surveyStepAnswers, storeVisitId, storesListId);

    // Sanitize any remaining base64 data
    this.sanitizeBase64Data(surveyStepAnswers);

    // Log state before stringifying
    console.log(
      'Before stringify - Survey answers:',
      JSON.stringify(surveyStepAnswers, null, 2)
    );
    surveyInstance.surveyData = JSON.stringify(surveyStepAnswers);
    // Log what was actually stringified
    console.log('After stringify - Survey data:', surveyInstance.surveyData);

    try {
      await firstValueFrom(
        this.surveysService.uploadSurveyResults(surveyInstance)
      );
      this.toast.success('Survey submitted successfully');
    } catch (error) {
      this.toast.error('Error submitting survey');
      throw error;
    }
  }

  private sanitizeBase64Data(surveyStepAnswers: SurveyStepResponse[]): void {
    const BASE64_PATTERN = /^data:image\/[a-z]+;base64,/;
    const SANITIZED_MESSAGE = 'Unprocessed photo data removed';

    for (const step of surveyStepAnswers) {
      if (Array.isArray(step.responses)) {
        if (step.responses.length > 0 && 'question' in step.responses[0]) {
          // Handle SurveyResponses
          for (const answer of step.responses as SurveyResponse[]) {
            if (Array.isArray(answer.response)) {
              answer.response = answer.response.map((item) =>
                typeof item === 'string' && BASE64_PATTERN.test(item)
                  ? SANITIZED_MESSAGE
                  : item
              );
            }
          }
        } else {
          // Handle LocationResponses
          for (const location of step.responses as LocationResponse[]) {
            if (
              typeof location.photo === 'string' &&
              BASE64_PATTERN.test(location.photo)
            ) {
              location.photo = SANITIZED_MESSAGE;
            }
          }
        }
      } else if ('items' in step.responses) {
        // Handle OrderResponse
        const orderResponse = step.responses as OrderResponse;
        if (
          typeof orderResponse.signature === 'string' &&
          BASE64_PATTERN.test(orderResponse.signature)
        ) {
          orderResponse.signature = SANITIZED_MESSAGE;
        }
        if (
          typeof orderResponse.photoReceipt === 'string' &&
          BASE64_PATTERN.test(orderResponse.photoReceipt)
        ) {
          orderResponse.photoReceipt = SANITIZED_MESSAGE;
        }
      }
    }
  }

  private async uploadPhotos(
    surveyStepAnswers: SurveyStepResponse[],
    storeVisitId: number,
    storesListId: number
  ) {
    const UPLOAD_FAILED_MESSAGE = 'Photo upload failed';

    for (const step of surveyStepAnswers) {
      console.log('Processing step:', step.questionGroupsName);
      if (Array.isArray(step.responses) && step.responses.length > 0) {
        if ('question' in step.responses[0]) {
          // Handle SurveyResponses
          for (const answer of step.responses as SurveyResponse[]) {
            if (
              answer.question.toLowerCase().includes('photo') &&
              answer.response &&
              typeof answer.response === 'object' &&
              'photos' in answer.response
            ) {
              const uploadResults = await Promise.all(
                answer.response.photos.map(
                  async (base64: string, index: number) => {
                    const description =
                      index === answer.response.coverImageIndex
                        ? 'StoreCoverPhoto'
                        : this.getDocumentDescription(step.questionGroupsName);
                    const result = await this.uploadPhoto(
                      base64,
                      description,
                      storeVisitId,
                      storesListId
                    );
                    return result || UPLOAD_FAILED_MESSAGE;
                  }
                )
              );
              answer.response = {
                photos: uploadResults,
                coverImageIndex: answer.response.coverImageIndex,
              };
            }
          }
        } else if (Array.isArray(step.responses)) {
          // Handle LocationResponses
          console.log('Processing LocationResponses:', step.responses);

          // Filter locations based on step name
          const isEntryStep = step.questionGroupsName
            .toLowerCase()
            .includes('entry');
          step.responses = (step.responses as LocationResponse[]).filter(
            (loc) => (isEntryStep ? !loc.isExitLocation : loc.isExitLocation)
          );

          // Create a map using array index as part of the unique key
          const locationIndexMap = new Map(
            (step.responses as LocationResponse[]).map((loc, index) => [
              `${loc.locationId}-${
                loc.isExitLocation ? 'exit' : 'entry'
              }-${index}`,
              index,
            ])
          );

          const locationUploads = (step.responses as LocationResponse[])
            .filter(
              (location) =>
                location.photo !== null &&
                typeof location.photo === 'string' &&
                location.photo.startsWith('data:')
            )
            .map(async (location, arrayIndex) => {
              const uploadResult = await this.uploadPhoto(
                location.photo as string,
                location.isExitLocation
                  ? 'DistributionOnExit'
                  : 'DistributionOnEntry',
                storeVisitId,
                storesListId
              );

              const index = locationIndexMap.get(
                `${location.locationId}-${
                  location.isExitLocation ? 'exit' : 'entry'
                }-${arrayIndex}`
              );
              if (index !== undefined) {
                (step.responses as LocationResponse[])[index].photo =
                  uploadResult || UPLOAD_FAILED_MESSAGE;
              }
            });

          await Promise.all(locationUploads);

          // Log the entire responses array after processing
          console.log(
            'After processing all locations:',
            JSON.stringify(step.responses, null, 2)
          );
        }
      } else if (!Array.isArray(step.responses) && 'items' in step.responses) {
        // Handle OrderResponse
        console.log(
          'Processing OrderResponse before:',
          JSON.stringify(step.responses, null, 2)
        );
        const orderResponse = step.responses as OrderResponse;

        // Create a new object to store the updated response
        const updatedOrderResponse: OrderResponse = {
          ...orderResponse,
          items: [...orderResponse.items],
        };

        if (
          orderResponse.signature &&
          orderResponse.signature.startsWith('data:')
        ) {
          const uploadResult = await this.uploadPhoto(
            orderResponse.signature,
            'PhotoOfOrder',
            storeVisitId,
            storesListId
          );
          updatedOrderResponse.signature =
            uploadResult || UPLOAD_FAILED_MESSAGE;
        }

        if (
          orderResponse.photoReceipt &&
          orderResponse.photoReceipt.startsWith('data:')
        ) {
          const uploadResult = await this.uploadPhoto(
            orderResponse.photoReceipt,
            'PhotoOfOrder',
            storeVisitId,
            storesListId
          );
          updatedOrderResponse.photoReceipt =
            uploadResult || UPLOAD_FAILED_MESSAGE;
        }

        // Update the step responses with the new order response
        step.responses = updatedOrderResponse;
        console.log(
          'Processing OrderResponse after:',
          JSON.stringify(step.responses, null, 2)
        );
      }
    }
    // Log the final state before submitting
    console.log(
      'Final survey answers state:',
      JSON.stringify(surveyStepAnswers, null, 2)
    );
  }

  private async uploadPhoto(
    base64: string,
    description: string,
    storeVisitId: number,
    storesListId: number
  ): Promise<string | undefined> {
    try {
      console.log(`Starting upload for ${description}`);
      const fileName = `${description.toLowerCase().replace(/\s+/g, '_')}.png`;
      const file = this.base64ToFile(base64, fileName);
      const uploadModel = this.createUploadModel(
        file,
        description,
        storeVisitId,
        storesListId
      );

      console.log(`Sending upload request for ${description}`);
      const response = await firstValueFrom(
        this.http.post<UploadDocumentUIModel>(
          `${environment.API_BASE_URL}/api/Documents/UploadDocument`,
          uploadModel
        )
      );
      console.log(
        `Upload successful for ${description}, URL:`,
        response?.document?.fileUrl
      );
      return response?.document?.fileUrl;
    } catch (error) {
      console.error('Error uploading photo:', error);
      this.toast.error('Error uploading photo');
      return undefined; // Return undefined instead of throwing to match SurveyResponse behavior
    }
  }

  private createUploadModel(
    file: File,
    description: string,
    storeVisitId: number,
    storesListId: number
  ): FormData {
    const uploadModel = new FormData();
    uploadModel.append('documentDescription', description);
    uploadModel.append('documentUpdatedBy', localStorage.getItem('userEmail')!);
    uploadModel.append('documentOriginalFilename', file.name);
    uploadModel.append('documentType', file.type);
    uploadModel.append('callingApplication', 'ClawStore');
    uploadModel.append('documentFile', file);
    uploadModel.append('storesListId', storesListId.toString());
    uploadModel.append('storeVisitsId', storeVisitId.toString());
    return uploadModel;
  }

  private getDocumentDescription(question: string): string {
    if (question.toLowerCase().includes('from the street')) {
      return 'FromTheStreetOnEntry';
    }
    if (question.toLowerCase().includes('distribution on entry')) {
      return 'DistributionOnEntry';
    }
    if (question.toLowerCase().includes('distribution on exit')) {
      return 'DistributionOnExit';
    }
    if (question.toLowerCase().includes('store information (on exit)')) {
      return 'FromTheStreetOnExit';
    }
    return '';
  }

  private base64ToFile(base64String: string, filename: string): File {
    try {
      console.log(`Converting base64 to file: ${filename}`);
      // Ensure we have a clean base64 string
      const base64 = base64String.split(';base64,').pop();
      if (!base64) {
        console.error('Invalid base64 string format');
        throw new Error('Invalid base64 string format');
      }

      const mimeType = this.getMimeType(base64String);
      console.log(`Detected MIME type: ${mimeType}`);

      // Decode base64
      const byteCharacters = atob(base64);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);
        const byteNumbers = new Array(slice.length);

        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const file = new File(byteArrays, filename, { type: mimeType });
      console.log(
        `File created successfully: ${filename}, size: ${file.size} bytes`
      );
      return file;
    } catch (error) {
      console.error('Error in base64ToFile:', error);
      throw error;
    }
  }

  private getMimeType(base64String: string): string {
    try {
      // Extract MIME type from base64 string or default to png
      const match = base64String.match(/^data:([^;]+);base64,/);
      const mimeType = match ? match[1] : 'image/png';
      return mimeType;
    } catch (error) {
      console.error('Error getting MIME type:', error);
      return 'image/png';
    }
  }
}
