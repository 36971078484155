<swal #modalContent [showConfirmButton]="false" [showCloseButton]="true">
  <div *swalPortal="swalTargets.content">
    <h2
      class="text-xl font-bold text-gray-900 mb-4 flex justify-start font-teko"
    >
      Add Store Note
    </h2>
    <hr class="border-gray-200 my-4" />
    <form (ngSubmit)="onSubmit()" #updateForm="ngForm" class="space-y-4">
      <div>
        <label
          for="note"
          class="block text-sm font-medium text-gray-700 mb-1 text-left font-teko"
          >Store Note</label
        >
        <textarea
          id="note"
          name="note"
          [(ngModel)]="note"
          class="block w-full px-3 py-2 rounded-lg bg-white border border-gray-300 text-sm"
          style="height: 200px"
        ></textarea>
      </div>
      <div class="mt-6 flex justify-start">
        <button
          type="submit"
          class="flex items-center justify-center text-black bg-custom-green font-medium rounded-lg text-sm px-4 py-2 h-[38px]"
          (click)="onSubmit()"
        >
          Add
        </button>
        <button
          type="button"
          class="flex items-center justify-center text-black bg-white font-medium rounded-lg text-sm px-4 py-2 h-[38px] border border-gray-300 ml-2"
          (click)="dismiss()"
        >
          Cancel
        </button>
      </div>
    </form>
  </div>
</swal>
