<swal
  #modalContent
  [swalOptions]="{ width: '600px' }"
  [showConfirmButton]="false"
  [showCloseButton]="true"
>
  <div *swalPortal="swalTargets.content">
    <h2
      class="text-xl font-bold text-gray-900 mb-4 flex justify-start font-teko uppercase text-center"
    >
      Found existing stores
    </h2>
    <div class="flex flex-col gap-4">
      <div
        *ngFor="let store of stores"
        class="p-4 border border-2 border-custom-green rounded"
      >
        <div class="flex flex-col gap-2">
          <div class="font-bold">{{ store.storeName }}</div>
          <div *ngIf="store.address1">{{ store.address1 }}</div>
          <div *ngIf="store.address2">{{ store.address2 }}</div>
          <div *ngIf="store.address3">{{ store.address3 }}</div>
          <div *ngIf="store.address4">{{ store.address4 }}</div>
          <div *ngIf="store.address5">{{ store.address5 }}</div>
          <div *ngIf="store.postcode">{{ store.postcode }}</div>

          <button
            class="flex items-center justify-center px-5 py-2.5 gap-2 rounded-lg border border-custom-green bg-custom-green dark:bg-custom-green-dark text-black font-proxima text-sm font-bold hover:bg-custom-green-light dark:hover:bg-custom-green mt-3"
            (click)="
              goToSummary(
                store.storesListId,
                store.storesId,
                store.tradeDevelopmentManagersId
              )
            "
          >
            <span *ngIf="store.tradeDevelopmentManagersId === currentTDMId"
              >Go to Summary</span
            >
            <span *ngIf="store.tradeDevelopmentManagersId !== currentTDMId"
              >Claim this store</span
            >
            <i class="fa-solid fa-arrow-right"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</swal>
