<div *ngIf="!isSubmitted">

  <form [formGroup]="bugForm" (ngSubmit)="onSubmit()">

  <div class="p-4">
    <label
      for="name"
      class="block text-sm font-medium text-gray-700 mb-1 text-left font-teko"
    >Name
      <span *ngIf="submitted && bugForm.controls['name'].valid" class="text-lime-500">
        <svg class="w-4 h-4 inline" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
          <path fill-rule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z" clip-rule="evenodd"/>
        </svg>
      </span>
      <span *ngIf="submitted && bugForm.controls['name'].invalid" class="text-red-500">
        <svg class="w-4 h-4 inline" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
          <path fill-rule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm7.707-3.707a1 1 0 0 0-1.414 1.414L10.586 12l-2.293 2.293a1 1 0 1 0 1.414 1.414L12 13.414l2.293 2.293a1 1 0 0 0 1.414-1.414L13.414 12l2.293-2.293a1 1 0 0 0-1.414-1.414L12 10.586 9.707 8.293Z" clip-rule="evenodd"/>
        </svg>
      </span>
    </label>
    <input
      type="text"
      id="name"
      name="name"
      formControlName="name"
      class="block w-full px-3 py-2 rounded-lg bg-white border border-gray-300 text-sm focus:border-lime-500 focus:ring-lime-500 focus:ring-1 focus:outline-none"
    />

    <label
      for="description"
      class="block text-sm font-medium text-gray-700 mb-1 text-left font-teko mt-2"
    >Description
      <span *ngIf="submitted && bugForm.controls['description'].valid" class="text-lime-500">
        <svg class="w-4 h-4 inline" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
          <path fill-rule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z" clip-rule="evenodd"/>
        </svg>
      </span>
      <span *ngIf="submitted && bugForm.controls['description'].invalid" class="text-red-500">
        <svg class="w-4 h-4 inline" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
          <path fill-rule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm7.707-3.707a1 1 0 0 0-1.414 1.414L10.586 12l-2.293 2.293a1 1 0 1 0 1.414 1.414L12 13.414l2.293 2.293a1 1 0 0 0 1.414-1.414L13.414 12l2.293-2.293a1 1 0 0 0-1.414-1.414L12 10.586 9.707 8.293Z" clip-rule="evenodd"/>
        </svg>
      </span>
    </label>
    <textarea
      id="description"
      name="description"
      formControlName="description"
      class="block w-full px-3 py-2 rounded-lg bg-white border border-gray-300 text-sm focus:border-lime-500 focus:ring-lime-500 focus:ring-1 focus:outline-none"
      style="height: 100px"
    ></textarea>

    <label
      for="url"
      class="block text-sm font-medium text-gray-700 mb-1 text-left font-teko mt-2"
    >URL
      <span *ngIf="submitted && bugForm.controls['description'].valid" class="text-lime-500">
        <svg class="w-4 h-4 inline" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
          <path fill-rule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z" clip-rule="evenodd"/>
        </svg>
      </span>
      <span *ngIf="submitted && bugForm.controls['description'].invalid" class="text-red-500">
        <svg class="w-4 h-4 inline" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
          <path fill-rule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm7.707-3.707a1 1 0 0 0-1.414 1.414L10.586 12l-2.293 2.293a1 1 0 1 0 1.414 1.414L12 13.414l2.293 2.293a1 1 0 0 0 1.414-1.414L13.414 12l2.293-2.293a1 1 0 0 0-1.414-1.414L12 10.586 9.707 8.293Z" clip-rule="evenodd"/>
        </svg>
      </span>
    </label>
    <input
      type="text"
      id="url"
      name="url"
      formControlName="url"
      class="block w-full px-3 py-2 rounded-lg bg-white border border-gray-300 text-sm focus:border-lime-500 focus:ring-lime-500 focus:ring-1 focus:outline-none"
      pattern="https:\/\/.*"
    />
    <div *ngIf="bugForm.get('url')?.hasError('pattern') && bugForm.get('url')?.touched">
      <span class="font-teko text-red-500 text-sm">Only HTTPS URLs are allowed ie https://clawstore.monsterenergy.com</span>
    </div>

    <label class="block text-sm font-medium text-gray-700 mb-1 text-left font-teko mt-2">
      Priority
      <span *ngIf="submitted && bugForm.controls['priority'].valid" class="text-lime-500">
                <svg class="w-4 h-4 inline" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                  <path fill-rule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z" clip-rule="evenodd"/>
                </svg>
              </span>
      <span *ngIf="submitted && bugForm.controls['priority'].invalid" class="text-red-500">
                <svg class="w-4 h-4 inline" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                  <path fill-rule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm7.707-3.707a1 1 0 0 0-1.414 1.414L10.586 12l-2.293 2.293a1 1 0 1 0 1.414 1.414L12 13.414l2.293 2.293a1 1 0 0 0 1.414-1.414L13.414 12l2.293-2.293a1 1 0 0 0-1.414-1.414L12 10.586 9.707 8.293Z" clip-rule="evenodd"/>
                </svg>
              </span>
    </label>
    <div class="relative">
      <button
        type="button"
        (click)="priorityDropdown($event)"
        class="appearance-none border text-sm rounded-lg block w-full p-2.5 bg-white border-gray-300 text-black pr-10 text-left"
      >
        <div class="flex items-center">
          <svg
            class="w-4 h-4 text-gray-800 dark:text-white mr-2"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path d="M13.09 3.294c1.924.95 3.422 1.69 5.472.692a1 1 0 0 1 1.438.9v9.54a1 1 0 0 1-.562.9c-2.981 1.45-5.382.24-7.25-.701a38.739 38.739 0 0 0-.622-.31c-1.033-.497-1.887-.812-2.756-.77-.76.036-1.672.357-2.81 1.396V21a1 1 0 1 1-2 0V4.971a1 1 0 0 1 .297-.71c1.522-1.506 2.967-2.185 4.417-2.255 1.407-.068 2.653.453 3.72.967.225.108.443.216.655.32Z"/>
          </svg>
          <span>{{ prioritySelectedOption ? prioritySelectedOption.name : 'Select an option' }}</span>
        </div>
        <div class="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
          <svg
            class="w-4 h-4 text-gray-500"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
          </svg>
        </div>
      </button>
      <!-- Dropdown Menu -->
      <div *ngIf="priorityDropdownOpen" class="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded shadow-lg text-sm">
        <ul>
          <li
            class="flex items-center px-4 py-2 cursor-pointer hover:bg-gray-100 text-red-500"
            (click)="prioritySelectOption({ name: 'Urgent', id: '1', value: '1' })"
          >
            <svg class="w-4 h-4 text-red-500 mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
              <path d="M13.09 3.294c1.924.95 3.422 1.69 5.472.692a1 1 0 0 1 1.438.9v9.54a1 1 0 0 1-.562.9c-2.981 1.45-5.382.24-7.25-.701a38.739 38.739 0 0 0-.622-.31c-1.033-.497-1.887-.812-2.756-.77-.76.036-1.672.357-2.81 1.396V21a1 1 0 1 1-2 0V4.971a1 1 0 0 1 .297-.71c1.522-1.506 2.967-2.185 4.417-2.255 1.407-.068 2.653.453 3.72.967.225.108.443.216.655.32Z"/>
            </svg>
            Urgent
          </li>
          <li
            class="flex items-center px-4 py-2 cursor-pointer hover:bg-gray-100 text-orange-500"
            (click)="prioritySelectOption({ name: 'High', id: '2', value: '2' })"
          >
            <svg class="w-4 h-4 text-orange-500 mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
              <path d="M13.09 3.294c1.924.95 3.422 1.69 5.472.692a1 1 0 0 1 1.438.9v9.54a1 1 0 0 1-.562.9c-2.981 1.45-5.382.24-7.25-.701a38.739 38.739 0 0 0-.622-.31c-1.033-.497-1.887-.812-2.756-.77-.76.036-1.672.357-2.81 1.396V21a1 1 0 1 1-2 0V4.971a1 1 0 0 1 .297-.71c1.522-1.506 2.967-2.185 4.417-2.255 1.407-.068 2.653.453 3.72.967.225.108.443.216.655.32Z"/>
            </svg>
            High
          </li>
          <li
            class="flex items-center px-4 py-2 cursor-pointer hover:bg-gray-100 text-blue-800"
            (click)="prioritySelectOption({ name: 'Normal', id: '3', value: '3' })"
          >
            <svg class="w-4 h-4 text-blue-500 mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
              <path d="M13.09 3.294c1.924.95 3.422 1.69 5.472.692a1 1 0 0 1 1.438.9v9.54a1 1 0 0 1-.562.9c-2.981 1.45-5.382.24-7.25-.701a38.739 38.739 0 0 0-.622-.31c-1.033-.497-1.887-.812-2.756-.77-.76.036-1.672.357-2.81 1.396V21a1 1 0 1 1-2 0V4.971a1 1 0 0 1 .297-.71c1.522-1.506 2.967-2.185 4.417-2.255 1.407-.068 2.653.453 3.72.967.225.108.443.216.655.32Z"/>
            </svg>
            Normal
          </li>
          <li
            class="flex items-center px-4 py-2 cursor-pointer hover:bg-gray-100 text-gray-500"
            (click)="prioritySelectOption({ name: 'Low', id: '4', value: '4' })"
          >
            <svg class="w-4 h-4 text-gray-500 mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
              <path d="M13.09 3.294c1.924.95 3.422 1.69 5.472.692a1 1 0 0 1 1.438.9v9.54a1 1 0 0 1-.562.9c-2.981 1.45-5.382.24-7.25-.701a38.739 38.739 0 0 0-.622-.31c-1.033-.497-1.887-.812-2.756-.77-.76.036-1.672.357-2.81 1.396V21a1 1 0 1 1-2 0V4.971a1 1 0 0 1 .297-.71c1.522-1.506 2.967-2.185 4.417-2.255 1.407-.068 2.653.453 3.72.967.225.108.443.216.655.32Z"/>
            </svg>
            Low
          </li>
        </ul>
      </div>
    </div>

    <button type="submit" class="w-full bg-lime-500 text-black font-bold font-teko text-sm p-4 mt-4 rounded">SUBMIT</button>

  </div>

</form>

</div>

<div *ngIf="isSubmitted" class="success-message flex flex-col items-center justify-center text-center p-4">

  <svg class="w-12 h-12 text-lime-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 5 9 4V3m5 2 1-1V3m-3 6v11m0-11a5 5 0 0 1 5 5m-5-5a5 5 0 0 0-5 5m5-5a4.959 4.959 0 0 1 2.973 1H15V8a3 3 0 0 0-6 0v2h.027A4.959 4.959 0 0 1 12 9Zm-5 5H5m2 0v2a5 5 0 0 0 10 0v-2m2.025 0H17m-9.975 4H6a1 1 0 0 0-1 1v2m12-3h1.025a1 1 0 0 1 1 1v2M16 11h1a1 1 0 0 0 1-1V8m-9.975 3H7a1 1 0 0 1-1-1V8"/>
  </svg>


  <div class="block text-2xl font-medium text-black mb-1 text-left font-teko mt-2">
    BUG REPORT SUBMITTED
  </div>

  <div class="block text-sm font-medium text-gray-700 mb-1 text-left font-proxima mt-2">
    Your issue is now under review and the team will resolve as soon as possible.
  </div>

</div>
