<div class="container mx-auto mt-4 px-2 p-4 bg-white dark:bg-gray-800 rounded-lg shadow-md pb-3">
  <h2 class="text-3xl font-bold font-teko text-center uppercase mb-4">
    Feedback
  </h2>
  <div class="flex gap-4 mb-4 px-4">
    <button
      (click)="activeForm = 'feature'"
      [class.bg-lime-500]="activeForm === 'feature'"
      [class.bg-gray-300]="activeForm !== 'feature'"
      class="text-black px-4 py-2 rounded font-teko font-bold text-sm flex-grow transition-colors duration-200"
    >
      FEATURE REQUEST
    </button>
    <button
      (click)="activeForm = 'bug'"
      [class.bg-lime-500]="activeForm === 'bug'"
      [class.bg-gray-300]="activeForm !== 'bug'"
      class="text-black px-4 py-2 rounded font-teko font-bold text-sm flex-grow transition-colors duration-200"
    >
      BUG REPORT
    </button>
  </div>

  <div
    *ngIf="activeForm === 'feature'"
  >
    <app-feature-form></app-feature-form>
  </div>

  <div
    *ngIf="activeForm === 'bug'"
  >
    <app-bug-form></app-bug-form>
  </div>
</div>
