<div
  *ngIf="show"
  class="fixed bottom-20 left-0 w-full bg-white dark:bg-gray-800 shadow-inner z-10 bg-opacity-85 dark:bg-opacity-85 backdrop-blur-sm"
>
  <div class="grid grid-cols-2 items-center px-3 py-4 max-w mx-auto">
    <div class="justify-self-start">
      <button
        *ngIf="leftButtonText"
        class="flex items-center justify-center px-5 py-2.5 gap-2 rounded-lg border border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-800 text-black dark:text-white font-proxima text-sm font-bold hover:bg-gray-50 dark:hover:bg-gray-700"
        (click)="leftButtonClick()"
      >
        <i class="{{ leftButtonIcon }}"></i>
        <span>{{ leftButtonText }}</span>
      </button>
    </div>
    <div class="justify-self-end">
      <button
        *ngIf="rightButtonText"
        class="flex items-center justify-center px-5 py-2.5 gap-2 rounded-lg border border-custom-green bg-custom-green dark:bg-custom-green-dark text-black font-proxima text-sm font-bold hover:bg-custom-green-light dark:hover:bg-custom-green"
        (click)="rightButtonClick()"
      >
        <span>{{ rightButtonText }}</span>
        <i class="{{ rightButtonIcon }}"></i>
      </button>
    </div>
  </div>
</div>
