<div
  class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
>
  <div class="bg-white rounded-lg p-6 max-w-2xl w-full">
    <div class="flex justify-between items-center mb-6">
      <h2 class="text-xl font-teko font-bold uppercase">
        {{ data[0].shortProductName }}
      </h2>
      <button (click)="close()" class="text-gray-500">&times;</button>
    </div>

    <table class="w-full">
      <thead>
        <tr
          class="text-sm font-proxima border-b border-gray-200 hover:bg-gray-50"
        >
          <th class="text-centerpb-4 w-1/8"></th>
          <th class="text-left pb-4 w-1/4">SKU</th>
          <th class="text-center pb-4 w-1/6">Type</th>
          <th class="text-center pb-4 w-1/6">Price Per Unit</th>
          <th class="text-center pb-4 w-1/5">Quantity</th>
          <th class="text-center pb-4 w-1/7">Cart</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let variant of data"
          class="text-sm font-proxima border-b border-gray-200 hover:bg-gray-50 items-center"
        >
          <td class="py-4">
            <img
              [src]="getImagePath(variant.logoFilename!)"
              class="w-8 h-12 mr-4 object-contain"
              [alt]="variant.fullProductName"
            />
          </td>
          <td class="py-4">
            <div class="flex items-left font-bold">
              {{ variant.fullProductName }}
            </div>
          </td>
          <td class="text-center py-4">
            <span
              [ngClass]="{
                'bg-gray-100 text-gray-800 dark:bg-gray-900 dark:text-gray-300':
                  getVariantType(variant.fullProductName!) === 'Single',
                'bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-300':
                  getVariantType(variant.fullProductName!) === 'Case',
                'bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-300':
                  getVariantType(variant.fullProductName!) === 'Multipack',
                'bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-300':
                  getVariantType(variant.fullProductName!) === 'Seeding',
              }"
              class="font-bold px-2.5 py-1 rounded"
            >
              {{
                getVariantType(variant.fullProductName!) === "Single"
                  ? "Single"
                  : getVariantType(variant.fullProductName!) === "Multipack"
                  ? "Multipack"
                  : getVariantType(variant.fullProductName!) === "Seeding"
                  ? "Seeding"
                  : getVariantType(variant.fullProductName!) === "Case"
                  ? "Case"
                  : "Free"
              }}
            </span>
          </td>
          <td class="text-center py-4">
            <button
              class="w-16 px-2 py-1 border rounded bg-gray-50 hover:bg-gray-100"
              (click)="openPriceInput(variant)"
            >
              {{ formatPrice(variant) }}
            </button>
          </td>
          <td class="text-center py-4 flex items-center justify-center">
            <app-number-input
              [value]="getCurrentQuantity(variant)"
              (valueChange)="handleQuantityChange($event, variant)"
            ></app-number-input>
          </td>
          <td class="text-center py-4">
            <button
              class="px-2 py-1 rounded border border-gray-300"
              (click)="addItemToCart(variant)"
              [ngClass]="{
                'bg-gray-800 text-white': itemInCart(variant),
                'bg-white text-black': !itemInCart(variant)
              }"
            >
              {{ itemInCart(variant) ? "Added" : "Add" }}
            </button>
          </td>
          <hr />
        </tr>
      </tbody>
    </table>

    <div class="flex justify-start mt-6 space-x-4">
      <button
        class="px-2 py-1 font-proxima text-xs font-bold bg-custom-green text-black rounded hover:bg-custom-green-dark"
        (click)="confirm()"
      >
        Confirm
      </button>
      <button
        class="px-2 py-1 font-proxima text-xs font-bold border rounded hover:bg-gray-100"
        (click)="close()"
      >
        Cancel
      </button>
    </div>
  </div>
</div>

<div
  *ngIf="showPriceModal"
  class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[60]"
>
  <div class="bg-white rounded-lg p-6 w-72">
    <div class="text-center mb-4">
      <h3 class="text-lg font-bold">Enter Price</h3>
      <p class="text-sm text-gray-600">
        {{ selectedVariant?.fullProductName }}
      </p>
    </div>

    <input
      #priceInput
      id="priceInput"
      type="text"
      inputmode="decimal"
      [value]="tempPrice"
      class="w-full text-center text-2xl p-4 border rounded mb-4"
      readonly
    />

    <div class="grid grid-cols-3 gap-2">
      <button
        *ngFor="let num of [1, 2, 3, 4, 5, 6, 7, 8, 9, '.', 0, '⌫']"
        class="p-4 text-xl border rounded hover:bg-gray-100"
        (click)="handleNumPad(num)"
      >
        {{ num }}
      </button>
    </div>

    <div class="flex justify-end mt-4 space-x-2">
      <button
        class="px-4 py-2 border rounded hover:bg-gray-100"
        (click)="cancelPriceEdit()"
      >
        Cancel
      </button>
      <button
        class="px-4 py-2 bg-custom-green text-white rounded hover:bg-custom-green-dark"
        (click)="confirmPrice()"
      >
        Confirm
      </button>
    </div>
  </div>
</div>
