import {
  Component,
  Renderer2,
  Inject,
  ViewChild,
  ElementRef,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { Subject, EMPTY, catchError } from 'rxjs';
import { DarkModeService } from '../services/dark-mode.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { IdentityService } from '../_shared/services/identity.service';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  animations: [
    trigger('fadeOut', [
      transition(':leave', [
        style({ opacity: 1 }),
        animate('500ms ease-out', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class LoginComponent implements OnInit, OnDestroy {
  darkMode: boolean = false;
  shouldPlayVideo: boolean = true;
  isNavigating: boolean = false;
  isLoggingIn: boolean = false;
  @ViewChild('videoElement') videoElement!: ElementRef<HTMLVideoElement>;
  private readonly destroy$ = new Subject<void>();
  signingIn: boolean = false;

  constructor(
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private darkModeService: DarkModeService,
    private router: Router,
    private identityService: IdentityService,
    private msalService: MsalService
  ) {
    // Subscribe to router events to detect navigation away from login
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd && event.url !== '/login') {
        this.isLoggingIn = false;
      }
    });
  }

  ngOnInit() {
    // Let app.component handle auth state and redirects
    this.setupDarkMode();
    this.checkVideoPlayback();

    // Reset login state on component init
    this.isLoggingIn = false;
  }

  login() {
    this.isLoggingIn = true;
    this.signingIn = true;
    this.msalService
      .loginRedirect()
      .pipe(
        catchError((error) => {
          console.error('Login error:', error);
          this.isLoggingIn = false;
          return EMPTY;
        })
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private setupDarkMode() {
    this.darkModeService.currentDarkMode.subscribe((darkMode) => {
      this.darkMode = darkMode;
      if (darkMode) {
        this.renderer.addClass(this.document.body, 'dark');
      } else {
        this.renderer.removeClass(this.document.body, 'dark');
      }
    });
  }

  private checkVideoPlayback() {
    // Check connection speed
    if ('connection' in navigator) {
      const conn = (navigator as any).connection;

      if (conn.saveData) {
        // Data saver is enabled
        this.shouldPlayVideo = false;
        return;
      }

      // Only play on fast connections
      if (
        conn.effectiveType === '4g' ||
        conn.effectiveType === 'wifi' ||
        conn.effectiveType === '5g'
      ) {
        this.shouldPlayVideo = true;
      }
    }

    // Could also check localStorage for user preference
    const userPref = localStorage.getItem('videoPreference');
    if (userPref === 'disabled') {
      this.shouldPlayVideo = false;
    }
  }
}
