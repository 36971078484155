<h5 class="text-xl font-proxima text-black dark:text-white p-2 text-center">
  Select the SKUs in this Location
</h5>

<!-- Group headers - horizontal scroll -->
<!-- <div class="flex overflow-x-auto hide-scrollbar">
  <ng-container *ngFor="let group of skuGroups">
    <img
      [ngSrc]="group.image"
      [alt]="group.name"
      class="w-[87px] h-[117px] rounded-lg object-contain flex-shrink-0"
      [width]="87"
      [height]="117"
      (click)="scrollToGroup(group)"
    />
  </ng-container>
</div> -->

<!-- SKUs by group - vertical scroll for groups -->
<div *ngFor="let group of visibleGroups" class="px-4">
  <div [id]="getProductGroup(group[0].logoFilename!)">
    <h6 class="text-xl font-teko font-bold uppercase my-4">
      {{ getProductGroup(group[0].logoFilename!) }}
    </h6>
    <div class="flex overflow-x-auto hide-scrollbar">
      <div *ngFor="let sku of group" class="flex-shrink-0 w-[95px] ml-4">
        <div class="relative inline-flex text-center flex-col">
          <img
            [ngSrc]="getImagePath(sku.logoFilename!)"
            [alt]="sku.fullProductName"
            [ngClass]="{
              'opacity-50 grayscale':
                getSkuAmount(sku.productsId!) !== undefined
            }"
            class="w-[95px] h-[250px] object-contain cursor-pointer"
            [width]="95"
            [height]="250"
            (click)="skuTap(sku.productsId!)"
          />
          <!-- Volume label -->
          <span class="text-sm text-black dark:text-white mt-1 font-proxima"
            >{{ sku.volume }}ml</span
          >

          <!-- Counter controls overlay -->
          <div
            *ngIf="getSkuAmount(sku.productsId!) !== undefined"
            class="absolute inset-0 flex flex-col items-center justify-center space-y-3"
          >
            <button
              class="inline-flex items-center justify-center w-10 h-10 bg-custom-green rounded-full touch-manipulation no-context-menu"
              (touchstart)="startIncrement(sku.productsId!, $event)"
              (touchend)="stopIncrement()"
            >
              <i class="fa-solid fa-plus text-white"></i>
            </button>

            <input
              (change)="skuNumberChange($event)"
              type="tel"
              id="{{ sku.productsId }}"
              data-input-counter
              class="flex-shrink-0 text-gray-900 font-medium rounded-full border border-gray-500 bg-gray-100 text-lg font-proxima focus:outline-none focus:ring-0 max-w-[3rem] text-center"
              value="{{ getSkuAmount(sku.productsId!) }}"
              required
            />

            <button
              class="inline-flex items-center justify-center w-10 h-10 bg-custom-green rounded-full touch-manipulation no-context-menu"
              (touchstart)="startDecrement(sku.productsId!, $event)"
              (touchend)="stopDecrement()"
            >
              <i class="fa-solid fa-minus text-white"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Scroll to top button -->
<button
  *ngIf="showScrollButton"
  class="fixed bottom-44 right-4 w-12 h-12 rounded-full bg-custom-green flex items-center justify-center shadow-lg z-50 touch-none"
  (click)="scrollToTop()"
>
  <i class="fa-solid fa-arrow-up text-white"></i>
</button>
