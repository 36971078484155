import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { IdentityService } from './identity.service';
import { Observable } from 'rxjs';

@Injectable()
export class InterceptorService implements HttpInterceptor {
  constructor(
    private route: Router,
    private identityService: IdentityService
  ) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const req = request.clone({
      headers: request.headers
        .set('X-Tenant', String(this.identityService.getUserTenant()))
        .set('X-Requested-With', 'XMLHttpRequest'),
    });
    return next.handle(req).pipe(
      tap({
        next: (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
          }
        },
        error: (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              if (!window.location.href.includes('/login')) {
                this.identityService.clearStoredData();
                this.route.navigate(['/login']);
              }
            }
          }
        },
      })
    );
  }
}
