import { Component, Input, Output, EventEmitter } from '@angular/core';

interface DropdownOption {
  value: string;
  label: string;
}

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
})
export class DropdownComponent {
  @Input() id: string = '';
  @Input() name: string = '';
  @Input() label: string = '';
  @Input() options: DropdownOption[] = [];

  @Input()
  set value(val: string | number | string[]) {
    this._value = val;
  }
  get value(): string | number | string[] {
    return this._value;
  }
  private _value: string | number | string[] = '';

  @Output() valueChange = new EventEmitter<string>();

  onChange(newValue: string) {
    this._value = newValue;
    this.valueChange.emit(newValue);
  }
}
