<div *ngIf="!isSubmitted">
  <form [formGroup]="featureForm" (ngSubmit)="onSubmit()">

    <div class="p-4">
      <label
        for="name"
        class="block text-sm font-medium text-gray-700 mb-1 text-left font-teko"
      >Name
        <span *ngIf="submitted && featureForm.controls['name'].valid" class="text-lime-500">
          <svg class="w-4 h-4 inline" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
            <path fill-rule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z" clip-rule="evenodd"/>
          </svg>
        </span>
        <span *ngIf="submitted && featureForm.controls['name'].invalid" class="text-red-500">
          <svg class="w-4 h-4 inline" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
            <path fill-rule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm7.707-3.707a1 1 0 0 0-1.414 1.414L10.586 12l-2.293 2.293a1 1 0 1 0 1.414 1.414L12 13.414l2.293 2.293a1 1 0 0 0 1.414-1.414L13.414 12l2.293-2.293a1 1 0 0 0-1.414-1.414L12 10.586 9.707 8.293Z" clip-rule="evenodd"/>
          </svg>
        </span>
      </label>
      <input
        type="text"
        id="name"
        name="name"
        formControlName="name"
        class="block w-full px-3 py-2 rounded-lg bg-white border border-gray-300 text-sm focus:border-lime-500 focus:ring-lime-500 focus:ring-1 focus:outline-none"
      />

      <label
        for="description"
        class="block text-sm font-medium text-gray-700 mb-1 text-left font-teko mt-2"
      >Description
        <span *ngIf="submitted && featureForm.controls['description'].valid" class="text-lime-500">
          <svg class="w-4 h-4 inline" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
            <path fill-rule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z" clip-rule="evenodd"/>
          </svg>
        </span>
        <span *ngIf="submitted && featureForm.controls['description'].invalid" class="text-red-500">
          <svg class="w-4 h-4 inline" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
            <path fill-rule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm7.707-3.707a1 1 0 0 0-1.414 1.414L10.586 12l-2.293 2.293a1 1 0 1 0 1.414 1.414L12 13.414l2.293 2.293a1 1 0 0 0 1.414-1.414L13.414 12l2.293-2.293a1 1 0 0 0-1.414-1.414L12 10.586 9.707 8.293Z" clip-rule="evenodd"/>
          </svg>
        </span>
      </label>
      <textarea
        id="description"
        name="description"
        formControlName="description"
        class="block w-full px-3 py-2 rounded-lg bg-white border border-gray-300 text-sm focus:border-lime-500 focus:ring-lime-500 focus:ring-1 focus:outline-none"
        style="height: 100px"
      ></textarea>

      <label class="block text-sm font-medium text-gray-700 mb-1 text-left font-teko mt-2">
        Priority
        <span *ngIf="submitted && featureForm.controls['priority'].valid" class="text-lime-500">
                  <svg class="w-4 h-4 inline" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                    <path fill-rule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z" clip-rule="evenodd"/>
                  </svg>
                </span>
        <span *ngIf="submitted && featureForm.controls['priority'].invalid" class="text-red-500">
                  <svg class="w-4 h-4 inline" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                    <path fill-rule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm7.707-3.707a1 1 0 0 0-1.414 1.414L10.586 12l-2.293 2.293a1 1 0 1 0 1.414 1.414L12 13.414l2.293 2.293a1 1 0 0 0 1.414-1.414L13.414 12l2.293-2.293a1 1 0 0 0-1.414-1.414L12 10.586 9.707 8.293Z" clip-rule="evenodd"/>
                  </svg>
                </span>
      </label>
      <div class="relative">
        <button
          type="button"
          (click)="priorityDropdown($event)"
          class="appearance-none border text-sm rounded-lg block w-full p-2.5 bg-white border-gray-300 text-black pr-10 text-left"
        >
          <div class="flex items-center">
            <svg
              class="w-4 h-4 text-gray-800 dark:text-white mr-2"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path d="M13.09 3.294c1.924.95 3.422 1.69 5.472.692a1 1 0 0 1 1.438.9v9.54a1 1 0 0 1-.562.9c-2.981 1.45-5.382.24-7.25-.701a38.739 38.739 0 0 0-.622-.31c-1.033-.497-1.887-.812-2.756-.77-.76.036-1.672.357-2.81 1.396V21a1 1 0 1 1-2 0V4.971a1 1 0 0 1 .297-.71c1.522-1.506 2.967-2.185 4.417-2.255 1.407-.068 2.653.453 3.72.967.225.108.443.216.655.32Z"/>
            </svg>
            <span>{{ prioritySelectedOption ? prioritySelectedOption.name : 'Select an option' }}</span>
          </div>
          <div class="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
            <svg
              class="w-4 h-4 text-gray-500"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
            </svg>
          </div>
        </button>
        <!-- Dropdown Menu -->
        <div *ngIf="priorityDropdownOpen" class="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded shadow-lg text-sm">
          <ul>
            <li
              class="flex items-center px-4 py-2 cursor-pointer hover:bg-gray-100 text-red-500"
              (click)="prioritySelectOption({ name: 'Urgent', id: '1', value: '1' })"
            >
              <svg class="w-4 h-4 text-red-500 mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                <path d="M13.09 3.294c1.924.95 3.422 1.69 5.472.692a1 1 0 0 1 1.438.9v9.54a1 1 0 0 1-.562.9c-2.981 1.45-5.382.24-7.25-.701a38.739 38.739 0 0 0-.622-.31c-1.033-.497-1.887-.812-2.756-.77-.76.036-1.672.357-2.81 1.396V21a1 1 0 1 1-2 0V4.971a1 1 0 0 1 .297-.71c1.522-1.506 2.967-2.185 4.417-2.255 1.407-.068 2.653.453 3.72.967.225.108.443.216.655.32Z"/>
              </svg>
              Urgent
            </li>
            <li
              class="flex items-center px-4 py-2 cursor-pointer hover:bg-gray-100 text-orange-500"
              (click)="prioritySelectOption({ name: 'High', id: '2', value: '2' })"
            >
              <svg class="w-4 h-4 text-orange-500 mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                <path d="M13.09 3.294c1.924.95 3.422 1.69 5.472.692a1 1 0 0 1 1.438.9v9.54a1 1 0 0 1-.562.9c-2.981 1.45-5.382.24-7.25-.701a38.739 38.739 0 0 0-.622-.31c-1.033-.497-1.887-.812-2.756-.77-.76.036-1.672.357-2.81 1.396V21a1 1 0 1 1-2 0V4.971a1 1 0 0 1 .297-.71c1.522-1.506 2.967-2.185 4.417-2.255 1.407-.068 2.653.453 3.72.967.225.108.443.216.655.32Z"/>
              </svg>
              High
            </li>
            <li
              class="flex items-center px-4 py-2 cursor-pointer hover:bg-gray-100 text-blue-800"
              (click)="prioritySelectOption({ name: 'Normal', id: '3', value: '3' })"
            >
              <svg class="w-4 h-4 text-blue-500 mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                <path d="M13.09 3.294c1.924.95 3.422 1.69 5.472.692a1 1 0 0 1 1.438.9v9.54a1 1 0 0 1-.562.9c-2.981 1.45-5.382.24-7.25-.701a38.739 38.739 0 0 0-.622-.31c-1.033-.497-1.887-.812-2.756-.77-.76.036-1.672.357-2.81 1.396V21a1 1 0 1 1-2 0V4.971a1 1 0 0 1 .297-.71c1.522-1.506 2.967-2.185 4.417-2.255 1.407-.068 2.653.453 3.72.967.225.108.443.216.655.32Z"/>
              </svg>
              Normal
            </li>
            <li
              class="flex items-center px-4 py-2 cursor-pointer hover:bg-gray-100 text-gray-500"
              (click)="prioritySelectOption({ name: 'Low', id: '4', value: '4' })"
            >
              <svg class="w-4 h-4 text-gray-500 mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                <path d="M13.09 3.294c1.924.95 3.422 1.69 5.472.692a1 1 0 0 1 1.438.9v9.54a1 1 0 0 1-.562.9c-2.981 1.45-5.382.24-7.25-.701a38.739 38.739 0 0 0-.622-.31c-1.033-.497-1.887-.812-2.756-.77-.76.036-1.672.357-2.81 1.396V21a1 1 0 1 1-2 0V4.971a1 1 0 0 1 .297-.71c1.522-1.506 2.967-2.185 4.417-2.255 1.407-.068 2.653.453 3.72.967.225.108.443.216.655.32Z"/>
              </svg>
              Low
            </li>
          </ul>
        </div>
      </div>

      <button type="submit" class="w-full bg-lime-500 text-black font-bold font-teko text-sm p-4 mt-4 rounded">SUBMIT</button>

    </div>

  </form>

</div>

<div *ngIf="isSubmitted" class="success-message flex flex-col items-center justify-center text-center p-4">

  <svg class="w-12 h-12 text-lime-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m8.032 12 1.984 1.984 4.96-4.96m4.55 5.272.893-.893a1.984 1.984 0 0 0 0-2.806l-.893-.893a1.984 1.984 0 0 1-.581-1.403V7.04a1.984 1.984 0 0 0-1.984-1.984h-1.262a1.983 1.983 0 0 1-1.403-.581l-.893-.893a1.984 1.984 0 0 0-2.806 0l-.893.893a1.984 1.984 0 0 1-1.403.581H7.04A1.984 1.984 0 0 0 5.055 7.04v1.262c0 .527-.209 1.031-.581 1.403l-.893.893a1.984 1.984 0 0 0 0 2.806l.893.893c.372.372.581.876.581 1.403v1.262a1.984 1.984 0 0 0 1.984 1.984h1.262c.527 0 1.031.209 1.403.581l.893.893a1.984 1.984 0 0 0 2.806 0l.893-.893a1.985 1.985 0 0 1 1.403-.581h1.262a1.984 1.984 0 0 0 1.984-1.984V15.7c0-.527.209-1.031.581-1.403Z"/>
  </svg>

  <div class="block text-2xl font-medium text-black mb-1 text-left font-teko mt-2">
    FEATURE REQUEST SUBMITTED
  </div>

  <div class="block text-sm font-medium text-gray-700 mb-1 text-left font-proxima mt-2">
    Your request is now under review and you will recieve confirmation once accepted.
  </div>

</div>

