import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AppSettings } from './common/appSettings';
import { EventMessage, EventType } from '@azure/msal-browser';
import { EMPTY, Subject, filter, takeUntil, catchError } from 'rxjs';
import { IdentityService } from './_shared/services/identity.service';
import { MsalService } from '@azure/msal-angular';
import { UsersService, UsersUIResponseModel } from './services/swagger.gen';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  showHeader = false;
  showFooter = false;
  isLoggedIn: boolean = false;
  private readonly destroy$ = new Subject<void>();

  constructor(
    private msalService: MsalService,
    public identityService: IdentityService,
    private router: Router,
    private appSettings: AppSettings,
    private usersService: UsersService
  ) {
    router.events
      .pipe(
        filter((val) => val instanceof NavigationEnd),
        takeUntil(this.destroy$)
      )
      .subscribe((val: any) => {
        this.showHeader =
          val.url !== '/login' && val.url !== '/' && val.url !== '/day-landing';
        this.showFooter =
          val.url !== '/login' && val.url !== '/' && val.url !== '/day-landing';
      });
  }

  async ngOnInit(): Promise<void> {
    try {
      console.log('Initializing MSAL...');
      await this.msalService.instance.initialize();
      console.log('Handling redirect promise...');
      const result = await this.msalService.instance.handleRedirectPromise();
      console.log('Redirect result:', result);

      if (result?.account) {
        console.log('Got account from redirect');
        await this.handleSuccessfulLogin(result.account);
      } else {
        const accounts = this.msalService.instance.getAllAccounts();
        console.log('Existing accounts:', accounts);
        if (accounts.length > 0) {
          console.log('Using existing account');
          this.msalService.instance.setActiveAccount(accounts[0]);
          await this.handleSuccessfulLogin(accounts[0]);
        } else {
          console.log('No accounts found, redirecting to login');
          this.router.navigate(['/login']);
        }
      }

      // Add event listener for logout success
      this.msalService.instance.addEventCallback((event: EventMessage) => {
        if (event.eventType === EventType.LOGOUT_SUCCESS) {
          localStorage.clear();
          this.router.navigate(['/login']);
        }
      });
    } catch (error) {
      console.error('MSAL initialization error:', error);
      this.router.navigate(['/login']);
    }
  }

  private async handleSuccessfulLogin(account: any): Promise<void> {
    this.isLoggedIn = true;
    this.identityService.setActiveAccount(account);

    // Fetch user data and store necessary IDs
    this.usersService
      .getUserData(this.identityService.getUserId())
      .pipe(
        takeUntil(this.destroy$),
        catchError((error) => {
          console.error('Error fetching user data:', error);
          return EMPTY;
        })
      )
      .subscribe((userData: UsersUIResponseModel) => {
        // Store relevant IDs
        localStorage.setItem('userId', userData.user?.userId?.toString() || '');
        console.log(localStorage.getItem('userId'));
        localStorage.setItem(
          'countryCode',
          userData.user?.countryCode?.toString() || ''
        );
        console.log(localStorage.getItem('countryCode'));
        localStorage.setItem('userName', userData.user?.userName || '');
        console.log(localStorage.getItem('userName'));
        localStorage.setItem('userEmail', userData.user?.eMail || '');
        console.log(localStorage.getItem('userEmail'));

        if (userData.isTDM) {
          localStorage.setItem(
            'tdmId',
            userData.clawStoreUsersId?.toString() || ''
          );
        }
        if (userData.isRFM) {
          localStorage.setItem(
            'rfmId',
            userData.clawStoreUsersId?.toString() || ''
          );
        }

        // Navigate to default route
        if (
          localStorage.getItem('selectedDayType') === '' ||
          localStorage.getItem('selectedDayType') === null ||
          this.shouldShowDayLanding()
        ) {
          this.router.navigate(['/day-landing']);
        } else {
          this.router.navigate(['/call-list']);
        }
      });
  }

  private shouldShowDayLanding(): boolean {
    const lastDayTypeDate = localStorage.getItem('dayTypeDate');
    if (!lastDayTypeDate) return true;

    const lastDate = new Date(lastDayTypeDate);
    const now = new Date();
    const today4am = new Date(now);
    today4am.setHours(4, 0, 0, 0);

    // Show landing if:
    // 1. It's a different day AND
    // 2. It's after 4am local time
    return now > today4am && lastDate < today4am;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
