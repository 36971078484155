import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  StoreNotesModel,
  StoresSummaryService,
  StoreSummaryUIModel,
} from '../services/swagger.gen';
import {
  EditStoreModalComponent,
  EditStoreModalMode as EditStoreModalMode,
} from './edit-store-modal/edit-store-modal.component';
import { ToastService } from '../_shared/services/toast.service';
import { AddStoreNoteComponent } from './add-store-note/add-store-note.component';
import Swal from 'sweetalert2';
import { ViewNotesModalComponent } from './view-notes-modal/view-notes-modal.component';

@Component({
  selector: 'app-store-summary',
  templateUrl: './store-summary.component.html',
  styleUrls: ['./store-summary.component.css'],
})
export class StoreSummaryComponent implements OnInit {
  storeData: StoreSummaryUIModel | null = null;

  storeAddress: string | null = null;
  storeOwner: string | null = '';
  storeOwnerJobTitle: string | null = 'Store Owner';
  storeOwnerEmail: string | null = '';
  storeOwnerPhone: string | null = '';

  storeNotes: StoreNotesModel[] = [];
  latestNote: StoreNotesModel | null = null;

  isLoading = true;
  storeImage = 'assets/dummy-store.jpg';

  @ViewChild(EditStoreModalComponent) editModal!: EditStoreModalComponent;
  editMode: EditStoreModalMode = EditStoreModalMode.All;

  @ViewChild(AddStoreNoteComponent) addNoteModal!: AddStoreNoteComponent;
  @ViewChild(ViewNotesModalComponent) viewNotesModal!: ViewNotesModalComponent;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private storeSummaryService: StoresSummaryService,
    private toastService: ToastService
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.loadStore();
  }

  storeUpdated() {
    this.toastService.success('Store updated');
    this.loadStore();
  }

  loadStore() {
    this.storeSummaryService
      .getSummary(this.route.snapshot.params['id'])
      .subscribe({
        next: (data) => {
          this.initializeComponent(data);
        },
        error: (error) => {
          this.toastService.error(error);
        },
        complete: () => {
          this.isLoading = false;
        },
      });
  }

  private initializeComponent(data: StoreSummaryUIModel) {
    this.storeData = data;
    this.concatAddress();
    this.storeNotes = data.storeNotes || [];

    // Check if storesContact, firstname, and surname are defined
    if (
      this.storeData?.storesContact?.firstname ||
      this.storeData?.storesContact?.surname
    ) {
      this.storeOwner = `${this.storeData.storesContact.firstname} ${this.storeData.storesContact.surname}`;
    } else {
      this.storeOwner = ''; // Set to blank string if undefined
    }

    // Use the latest note instead of the first one
    if (this.storeData?.storeNotes && this.storeData.storeNotes.length > 0) {
      this.latestNote = this.storeData.storeNotes.sort((a, b) => {
        const dateA =
          a.lastUpdated instanceof Date
            ? a.lastUpdated
            : (a.lastUpdated as any).toDate();
        const dateB =
          b.lastUpdated instanceof Date
            ? b.lastUpdated
            : (b.lastUpdated as any).toDate();
        return dateB.getTime() - dateA.getTime();
      })[0];
    } else {
      this.latestNote = null; // or an appropriate default value
    }
  }

  private concatAddress() {
    this.storeAddress = `${this.storeData?.address1}`;

    if (this.storeData?.address2) {
      this.storeAddress += `, ${this.storeData.address2}`;
    }
    if (this.storeData?.address3) {
      this.storeAddress += `, ${this.storeData.address3}`;
    }
    if (this.storeData?.address4) {
      this.storeAddress += `, ${this.storeData.address4}`;
    }

    this.storeAddress += `, ${this.storeData?.postcode}`;
  }

  editName() {
    this.editMode = EditStoreModalMode.Name;
    this.editModal.open();
  }

  editPhone() {
    this.editMode = EditStoreModalMode.Telephone;
    this.editModal.open();
  }

  editEmail() {
    this.editMode = EditStoreModalMode.Email;
    this.editModal.open();
  }

  editAll() {
    this.editMode = EditStoreModalMode.All;
    this.editModal.open();
  }

  viewNotes() {
    this.viewNotesModal.open();
  }

  addNote() {
    this.addNoteModal.open();
  }

  goBack() {
    this.router.navigate(['/call-list']);
  }

  startCall() {
    Swal.fire({
      title: 'Ready to begin the store visit?',
      icon: 'info',
      iconHtml: '<i class="fas fa-info-circle text-grey-500"></i>',
      showCancelButton: true,
      confirmButtonText: '<i class="fas fa-check"></i> Confirm',
      cancelButtonText: 'Close',
      customClass: {
        icon: 'no-border',
        confirmButton: 'btn btn-success bg-custom-green text-black',
        title: 'swal2-title-small',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigate(['/survey', this.storeData?.storesId]);
      }
    });
  }

  getImageUrl(): string {
    return this.storeData?.storeCoverPhoto &&
      this.storeData.storeCoverPhoto !== ''
      ? this.storeData.storeCoverPhoto.match(
          /(.*?\.(?:png|jpg|jpeg|gif|bmp|webp))/i
        )?.[1] ?? this.storeImage
      : this.storeImage;
  }
}
