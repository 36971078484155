import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-number-input',
  templateUrl: './number-input.component.html',
})
export class NumberInputComponent {
  @Input() id: string = '';
  @Input() name: string = '';
  @Input() label: string = '';
  @Input() value: number | string | string[] = 0;
  @Input() min: number = 0;
  @Input() max: number = 100;

  @Output() valueChange = new EventEmitter<number>();

  increment() {
    if (Number(this.value) < this.max) {
      this.value = Number(this.value) + 1;
      this.valueChange.emit(Number(this.value));
    }
  }

  decrement() {
    if (Number(this.value) > this.min) {
      this.value = Number(this.value) - 1;
      this.valueChange.emit(Number(this.value));
    }
  }
}
