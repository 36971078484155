import {
  Component,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnDestroy,
  Input,
} from '@angular/core';
import SignaturePad from 'signature_pad';

@Component({
  selector: 'app-order-signature',
  templateUrl: './order-signature.component.html',
})
export class OrderSignatureComponent implements AfterViewInit, OnDestroy {
  @ViewChild('signatureCanvas') signatureCanvas!: ElementRef;
  private signaturePad!: SignaturePad;
  isEmpty: boolean = true;
  private signatureData: string = '';

  @Input() total: number = 0;

  ngAfterViewInit() {
    this.initializeSignaturePad();
    window.addEventListener('resize', this.resizeCanvas.bind(this));
  }

  ngOnDestroy() {
    window.removeEventListener('resize', this.resizeCanvas.bind(this));
  }

  private initializeSignaturePad() {
    const canvas = this.signatureCanvas.nativeElement;
    this.resizeCanvas();

    this.signaturePad = new SignaturePad(canvas, {
      backgroundColor: 'rgb(255, 255, 255)',
      penColor: 'rgb(0, 0, 0)',
      velocityFilterWeight: 0.7,
    });

    this.signaturePad.addEventListener('beginStroke', () => {
      this.isEmpty = false;
    });

    this.signaturePad.addEventListener('clear', () => {
      this.isEmpty = true;
    });
  }

  private resizeCanvas() {
    const canvas = this.signatureCanvas.nativeElement;
    const ratio = Math.max(window.devicePixelRatio || 1, 1);

    canvas.width = canvas.offsetWidth * ratio;
    canvas.height = canvas.offsetHeight * ratio;
    canvas.getContext('2d').scale(ratio, ratio);

    if (this.signaturePad) {
      this.signaturePad.clear();
    }
  }

  clearSignature() {
    this.signaturePad.clear();
    this.isEmpty = true;
    this.signatureData = '';
  }

  getSignature(): string {
    if (this.isEmpty) return '';
    return this.signaturePad.toDataURL('image/png');
  }
}
