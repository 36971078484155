<div class="relative h-screen" [@fadeOut]="isNavigating ? 'void' : null">
  <!-- Static Background -->
  <div class="absolute inset-0 z-0">
    <img
      src="../assets/images/smoke-static.png"
      class="w-full h-full object-cover"
      alt=""
    />
  </div>

  <!-- Video Background -->
  <video
    #videoElement
    *ngIf="shouldPlayVideo"
    class="absolute inset-0 w-full h-full object-cover z-[1] opacity-100"
    autoplay
    muted
    loop
    playsinline
    preload="auto"
    defaultMuted
    [muted]="true"
  >
    <source src="../assets/videos/smoke-background.webm" type="video/webm" />
    <source src="../assets/videos/smoke-background.mp4" type="video/mp4" />
  </video>

  <!-- Gradient overlay - changed z-index to be above video -->
  <div
    class="absolute inset-0 bg-gradient-to-b from-black/20 to-black z-[2]"
  ></div>

  <div class="flex h-screen items-start justify-center relative z-[3] pt-20">
    <div class="bg-white rounded-lg p-4 w-[36rem] flex flex-col items-center">
      <!-- Monster Logo -->
      <img
        src="../assets/clawstore-logo.png"
        class="w-60 m-10"
        alt="Monster Logo"
      />

      <!-- Welcome Text -->
      <!-- <h2 class="font-teko text-2xl font-bold mb-6">WELCOME BACK</h2> -->
      <!-- <h4 class="font-teko text-2xl font-bold mb-6">Signing you in</h4> -->

      <!-- Sign in Button -->
      <button
        (click)="login()"
        [disabled]="isLoggingIn"
        class="w-full py-2 mb-4 bg-zinc-900 text-white rounded-md font-proxima hover:bg-zinc-800 disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center gap-2"
      >
        <img src="../assets/Claw-Icon.png" class="w-5" alt="Monster Logo" />
        <span>{{
          isLoggingIn ? "Signing in..." : "Sign in with Monster"
        }}</span>
      </button>
    </div>
  </div>
</div>
