export const environment = {
  production: false,
  API_BASE_URL:
    'https://clawstore-web-staging-gkh8ccbkgxgvhyga.uksouth-01.azurewebsites.net',
  // msal config details
  //  from: https://medium.com/@ankit4b/a-simplified-guide-to-msal-integration-with-angular-16-24c15cc8130e
  mslConfig: {
    clientId: '',
    authority: '',
    redirectUri: '',
  },
  AD: {
    instance: 'https://login.microsoftonline.com/',
    domain: 'MonsterenergyCorp.onmicrosoft.com',
    tenantId: 'f01d65ba-e57e-4f8f-8143-a43ed634f3c2',
    clientId: 'd4898d85-d392-4a94-a70a-96cbaf8ea134',
    callbackPath: 'Login/LoginResponse',
    postLogoutRedirectUri: 'https://clawstore-staging.monsterenergy.com/login',
    redirectUri: 'https://clawstore-staging.monsterenergy.com/',
    popUp: false,
    cacheLocation: 'localStorage',
    apiScope: 'api://d4898d85-d392-4a94-a70a-96cbaf8ea134/user_impersonation',
    navigateToLoginRequestUrl: true,
  },
};
