import { NgModule, isDevMode, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import * as SignaturePad from 'signature_pad';
import { NgOptimizedImage } from '@angular/common';
import {
  DocumentsService,
  TradeDevelopmentManagersService,
  UsersService,
} from './services/swagger.gen';
import { StoresListService } from './services/swagger.gen';
import { StoresSummaryService } from './services/swagger.gen';
import { FavouritesService } from './services/swagger.gen';
import { StoreVisitsService } from './services/swagger.gen';
import { ProductsService } from './services/swagger.gen';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { LeftsidebarComponent } from './layout/leftsidebar/leftsidebar.component';
import { NotificationsComponent } from './layout/notifications/notifications.component';
import { CallListComponent } from './call-list/call-list.component';
import { StoreSummaryComponent } from './store-summary/store-summary.component';
import { EditStoreModalComponent } from './store-summary/edit-store-modal/edit-store-modal.component';
import { LoadingSpinnerComponent } from './_shared/components/loading-spinner/loading-spinner.component';
import { AddNewStoreComponent } from './add-new-store/add-new-store.component';
import { PostcodePipe } from './call-list/postcode.pipe';
import { SortIconPipe } from './call-list/sort-icon.pipe';
import { RemoveIconPipe } from './call-list/remove-icon.pipe';
import { AddStoreNoteComponent } from './store-summary/add-store-note/add-store-note.component';
import { DropdownComponent } from './survey/components/dropdown/dropdown.component';
import { TextFieldComponent } from './survey/components/text-field/text-field.component';
import { NumberInputComponent } from './survey/components/number-input/number-input.component';
import { SurveyPageComponent } from './survey/survey-page/survey-page.component';
import { PhotoUploadComponent } from './survey/components/photo-upload/photo-upload.component';
import { DropdownMultiComponent } from './survey/components/dropdown-multi/dropdown-multi.component';
import { SurveysService } from './services/swagger.gen';
import { BottomNavBarComponent } from './_shared/components/bottom-nav-bar/bottom-nav-bar.component';
import { DayLandingPageComponent } from './day-landing-page/day-landing-page.component';
import { AppSettings } from './common/appSettings';
import {
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalModule,
  MsalService,
} from '@azure/msal-angular';
import { PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { environment } from '../environments/environment';
import { DistributionRepeaterComponent } from './survey/components/distribution-repeater/distribution-repeater.component';
import { StoreLocationCardComponent } from './survey/components/store-location-card/store-location-card.component';
import { SkuSelectorComponent } from './survey/components/sku-selector/sku-selector.component';
import { LocationSelectorComponent } from './survey/components/location-selector/location-selector.component';
import { SingleImageSelectorComponent } from './survey/components/single-image-selector/single-image-selector.component';
import { LocationSelectorModalComponent } from './survey/components/location-selector/location-selector-modal.component';
import { OnOffBadgeComponent } from './_shared/components/on-off-badge/on-off-badge.component';
import { LocationDiscontinueModalComponent } from './survey/components/distribution-repeater/location-discontinue-modal.component';
import { EndStoreVisitComponent } from './survey/end-store-visit/end-store-visit.component';
import { OrderPageComponent } from './survey/order-page/order-page.component';
import { OrderSelectorModalComponent } from './survey/order-page/order-selector-modal/order-selector-modal.component';
import { OrderSummaryComponent } from './survey/order-page/order-summary/order-summary.component';
import { OrderSignatureComponent } from './survey/order-page/order-signature/order-signature.component';
import { OrderReceiptComponent } from './survey/order-page/order-receipt/order-receipt.component';
import { WholesalePageComponent } from './wholesale-page/wholesale-page.component';
import { FeedbackPageComponent } from './feedback-page/feedback-page.component';
import { IdentityService } from './_shared/services/identity.service';
import { RouteGuard } from './_shared/services/routeguard.service';
import { InterceptorService } from './_shared/services/interceptor.service';
import { BugFormComponent } from './feedback-page/bug-form/bug-form.component';
import { FeatureFormComponent } from './feedback-page/feature-form/feature-form.component';
import { MainComponent } from './layout/main/main.component';
import { StoreCheckerModalComponent } from './add-new-store/store-checker-modal/store-checker-modal.component';
import { LoginComponent } from './login/login.component';

// MSAL Imports
import {
  MSAL_INSTANCE,
  MSAL_GUARD_CONFIG,
  MSAL_INTERCEPTOR_CONFIG,
  MsalGuardConfiguration,
  MsalInterceptorConfiguration,
} from '@azure/msal-angular';
import { ViewNotesModalComponent } from './store-summary/view-notes-modal/view-notes-modal.component';

// const msalConfig = environment.mslConfig;
const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

const msalConfig = {
  auth: {
    clientId: environment.AD.clientId,
    authority: `${environment.AD.instance}${environment.AD.tenantId}`,
    redirectUri: environment.AD.redirectUri,
    navigateToLoginRequestUrl: true,
    postLogoutRedirectUri: environment.AD.postLogoutRedirectUri,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: isIE,
  },
  system: {
    allowNativeBroker: false, // Disables Windows native broker
    loggerOptions: {
      loggerCallback: () => {},
      piiLoggingEnabled: false,
    },
  },
};

const msalAngularConfig: MsalGuardConfiguration = {
  interactionType: InteractionType.Redirect,
  authRequest: {
    scopes: ['user.read'],
  },
};

function MSALInstanceFactory(): PublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return msalAngularConfig;
}

const msalInterceptorConfig: MsalInterceptorConfiguration = {
  interactionType: InteractionType.Redirect,
  protectedResourceMap: new Map<string, Array<string>>([
    ['https://graph.microsoft.com/v1.0/me', ['User.Read']],
    [
      environment.API_BASE_URL,
      ['api://d4898d85-d392-4a94-a70a-96cbaf8ea134/user_impersonation'],
    ],
  ]),
};

function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  return msalInterceptorConfig;
}

@NgModule({
  schemas: [NO_ERRORS_SCHEMA],
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LeftsidebarComponent,
    NotificationsComponent,
    CallListComponent,
    StoreSummaryComponent,
    EditStoreModalComponent,
    AddNewStoreComponent,
    LoadingSpinnerComponent,
    PostcodePipe,
    SortIconPipe,
    RemoveIconPipe,
    EditStoreModalComponent,
    StoreSummaryComponent,
    AddStoreNoteComponent,
    DropdownComponent,
    TextFieldComponent,
    NumberInputComponent,
    SurveyPageComponent,
    PhotoUploadComponent,
    DropdownMultiComponent,
    BottomNavBarComponent,
    DayLandingPageComponent,
    DistributionRepeaterComponent,
    StoreLocationCardComponent,
    LocationSelectorComponent,
    SingleImageSelectorComponent,
    LocationSelectorModalComponent,
    OnOffBadgeComponent,
    LocationDiscontinueModalComponent,
    EndStoreVisitComponent,
    OrderPageComponent,
    OrderSelectorModalComponent,
    OrderSummaryComponent,
    OrderSignatureComponent,
    OrderReceiptComponent,
    WholesalePageComponent,
    FeedbackPageComponent,
    MainComponent,
    StoreCheckerModalComponent,
    BugFormComponent,
    FeatureFormComponent,
    LoginComponent,
    ViewNotesModalComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SweetAlert2Module.forRoot(),
    BrowserAnimationsModule,
    SkuSelectorComponent,
    MsalModule.forRoot(
      new PublicClientApplication({
        auth: {
          clientId: environment.AD.clientId,
          authority: `${environment.AD.instance}${environment.AD.tenantId}`,
          redirectUri: environment.AD.redirectUri,
          postLogoutRedirectUri: environment.AD.postLogoutRedirectUri,
          navigateToLoginRequestUrl: false,
        },
        cache: {
          cacheLocation: 'localStorage',
          storeAuthStateInCookie: isIE,
        },
      }),
      {
        interactionType: InteractionType.Redirect, // Msal Guard Configuration
        authRequest: {
          scopes: ['user.read'],
        },
      },
      {
        interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
        protectedResourceMap: new Map([
          ['https://graph.microsoft.com/v1.0/me', ['user.read']],
          // TODO: implement the interceptor          [environment.dataApiUrl, [environment.dataApiResourceScope]],
        ]),
      }
    ),
  ],

  exports: [],
  providers: [
    DocumentsService,
    TradeDevelopmentManagersService,
    StoresListService,
    FavouritesService,
    ProductsService,
    StoreVisitsService,
    StoresSummaryService,
    SurveysService,
    UsersService,
    AppSettings,
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    RouteGuard,
    IdentityService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
