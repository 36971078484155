import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MsalService } from "@azure/msal-angular";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { FeaturesClickupApiService } from "../../services/api/features-clickup-api.service";
import { InteractionRequiredAuthError } from "@azure/msal-browser";

@Component({
  selector: 'app-feature-form',
  templateUrl: './feature-form.component.html',
})
export class FeatureFormComponent implements OnInit {

  isSubmitted: boolean = false;
  featureForm!: FormGroup;
  submitted = false;
  firstName: string | null = null;
  lastName: string | null = null;
  email: string | null = null;
  jobTitle: string | null = null;
  priorityDropdownOpen = false;
  prioritySelectedOption: { name: string; id: string, value: string } | null = null;


  constructor(
    private fb: FormBuilder,
    private msalService: MsalService,
    private http: HttpClient,
    private router: Router,
    private clickupApi: FeaturesClickupApiService,
  ) { }

  ngOnInit() {
    this.getUserProfile();
    this.featureForm = this.fb.group({
      name: ['', Validators.required],
      description: ['', Validators.required],
      priority: ['', Validators.required],
    });
  }

  onSubmit() {
    this.submitted = true;

    if (this.featureForm.valid) {
      const fullName = `${this.firstName} ${this.lastName}`;

      const formValues = this.featureForm.value;

      const payload = {
        ...formValues,
        custom_fields: [
          {
            id: 'a8863257-badc-4467-ac74-69059ec8a1e5',
            value: fullName,
          },
          {
            id: '2bf42952-6e83-46ab-941b-0cb15938f831',
            value: this.jobTitle,
          },
          {
            id: 'ee603f5a-cbcd-4b8e-9034-7a2d80da3b59',
            value: this.email,
          },
        ]
      }

      this.clickupApi.createTask(payload).subscribe(
        response => {
          console.log('Task created successfully:', response);
          this.isSubmitted = true;
        },
        error => {
          console.error('Error creating task:', error);
        }
      );
    } else {
      console.error('Form is invalid');
    }
  }


  private getUserProfile(): void {
    const account = this.msalService.instance.getActiveAccount();
    if (account) {
      this.msalService.instance.acquireTokenSilent({
        scopes: ['User.Read'],
        account: account
      }).then(response => {
        this.http.get('https://graph.microsoft.com/v1.0/me', {
          headers: {
            'Authorization': `Bearer ${response.accessToken}`,
          }
        }).subscribe((profile: any) => {
          this.firstName = profile.givenName;
          this.lastName = profile.surname;
          this.jobTitle = profile.jobTitle;
          this.email = profile.mail;
        }, error => {
          console.error('Error fetching user profile:', error);
        });
      }).catch(error => {
        console.error('Error acquiring token silently:', error);
        if (error instanceof InteractionRequiredAuthError) {
          this.msalService.instance.acquireTokenRedirect({
            scopes: ['User.Read']
          });
        }
      });
    }
  }

  priorityDropdown(event: Event) {
    event.stopPropagation();
    this.priorityDropdownOpen = !this.priorityDropdownOpen;
  }

  prioritySelectOption(option: { name: string; id: string, value: string }): void {
    this.prioritySelectedOption = option;
    this.featureForm.controls['priority'].setValue(option.id);
    this.priorityDropdownOpen = false;
  }

}

