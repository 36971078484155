import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  OnInit,
  AfterViewInit,
} from '@angular/core';
import { QuestionOptionsUIModel } from 'src/app/services/swagger.gen';
import { LocationSelectorModalComponent } from './location-selector-modal.component';
import { LocationResponse } from '../../../services/survey-submission.service';

@Component({
  selector: 'app-location-selector',
  templateUrl: './location-selector.component.html',
})
export class LocationSelectorComponent implements OnInit, AfterViewInit {
  @Input() options!: QuestionOptionsUIModel[];
  @Input() response!: LocationResponse;

  @Output() cancelEvent = new EventEmitter<void>();
  @Output() confirmEvent = new EventEmitter<void>();
  photo: string | null = null;

  // Stack to keep track of navigation history
  navigationStack: QuestionOptionsUIModel[][] = [];

  // Current options being displayed
  currentOptions: QuestionOptionsUIModel[] = [];

  // Currently selected option
  currentOption: QuestionOptionsUIModel | null = null;

  previousDescription: string = '';
  showLocations: boolean = true;

  @ViewChild(LocationSelectorModalComponent)
  locationSelectorModal!: LocationSelectorModalComponent;

  ngOnInit() {
    this.currentOptions = this.options;
  }

  // open modal only if location is already selected ie onExit
  ngAfterViewInit() {
    if (this.response.location) {
      this.showLocations = false;
      this.locationSelectorModal.open();
    }
  }

  selectOption(option: QuestionOptionsUIModel) {
    this.currentOption = option;
    this.previousDescription = option.description!;

    if (option.childQuestions && option.childQuestions.length > 0) {
      this.navigationStack.push(this.currentOptions);
      this.currentOptions = option.childQuestions;
      return;
    }

    this.response.location = option.description!;
    this.response.locationId = option.questionOptionsId!;
    this.locationSelectorModal.open();
  }

  goBack() {
    if (this.navigationStack.length === 0) {
      if (this.showLocations) {
        this.response.location = '';
        this.response.locationId = 0;
      }
      this.cancelEvent.emit();
      return;
    }

    this.currentOptions = this.navigationStack.pop()!;
    this.currentOption = null;
  }

  isFinalPage(): boolean {
    return this.isLeafOption(this.currentOptions[0]);
  }

  isLeafOption(option: QuestionOptionsUIModel): boolean {
    return !option.childQuestions || option.childQuestions.length === 0;
  }

  onModalConfirm() {
    this.locationSelectorModal.close();
    this.confirmEvent.emit();
  }

  onModalCancel() {
    this.locationSelectorModal.close();
    this.goBack();
  }
}
