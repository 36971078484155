import { Component } from '@angular/core';
import { Router } from '@angular/router';

type DayType = 'tdm' | 'bottler' | 'partner' | 'rfm';

@Component({
  selector: 'app-day-landing-page',
  templateUrl: './day-landing-page.component.html',
})
export class DayLandingPageComponent {
  selectedType: DayType = 'tdm';

  constructor(private router: Router) {}

  selectDayType(type: DayType): void {
    this.selectedType = type;
  }

  onEnter(): void {
    if (!this.selectedType) {
      // Could add a toast notification here for better UX
      return;
    }

    // Store the selected day type (could use a service instead)
    localStorage.setItem('selectedDayType', this.selectedType);
    localStorage.setItem('dayTypeDate', new Date().toISOString());

    // Navigate to the main application view
    this.router.navigate(['/call-list']);
  }

  // Optional: Method to check if a type is selected (for button state)
  isDayTypeSelected(type: DayType): boolean {
    return this.selectedType === type;
  }

  // Optional: Method to get display text for selected type
  getDayTypeDisplay(type: DayType): string {
    const displayMap = {
      tdm: 'TDM Day in Trade',
      bottler: 'Bottler Day in Trade (DIT)',
      partner: 'Trade Partner Day in Trade',
      rfm: 'Regional Field Manager Day in Trade',
    };
    return displayMap[type];
  }
}
