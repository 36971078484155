<app-loading-spinner [isLoading]="isLoading"></app-loading-spinner>
<div
  *ngIf="!isLoading"
  class="container mx-auto mt-4 px-2 bg-white dark:bg-gray-800 rounded-lg shadow-md pb-36"
>
  <!-- Store Cover Photo -->
  <div class="flex flex-col items-start gap-[10px] self-stretch mb-16 p-[10px]">
    <img
      [src]="getImageUrl()"
      alt="Store Cover Photo"
      class="w-full h-auto object-contain rounded-lg"
    />
  </div>

  <!-- Store Information Card -->
  <div
    class="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6 -mt-48 relative mx-auto max-w-xl"
  >
    <!-- Store Name and Address -->
    <div class="text-center mb-6">
      <h1
        class="text-3xl font-teko font-bold text-black dark:text-white font-weight-700 mb-2"
      >
        {{ storeData?.storeName }}
      </h1>
      <div
        *ngIf="storeOwner !== ''"
        class="text-gray-600 dark:text-gray-400 mb-1"
      >
        <span class="font-bold">{{ storeOwner }} </span>
        <span *ngIf="storeData?.storesContact?.jobTitle" class="font-normal"
          >({{ storeData?.storesContact?.jobTitle }})</span
        >
      </div>
      <p class="text-gray-600 dark:text-gray-400 mb-2">
        {{ storeAddress }}
      </p>
      <button
        class="text-green-700 mt-1 font-weight-500"
        (click)="this.editAll()"
      >
        Edit <i class="fas fa-angle-right text-xs"></i>
      </button>
    </div>

    <hr class="border-gray-300 dark:border-gray-600 my-4" />

    <!-- Managed By -->
    <div class="text-center my-6">
      <p class="text-gray-600 dark:text-gray-400">
        <span class="font-normal">Managed by: <b>Bottle Partner</b></span>
        <span class="font-bold"></span>
      </p>
    </div>

    <hr class="border-gray-300 dark:border-gray-600 my-4" />

    <!-- Contact Information -->
    <div class="grid grid-cols-3 gap-6 mt-6">
      <div class="flex flex-col items-center text-center">
        <p class="font-bold text-black dark:text-white mb-2 font-weight-700">
          <i class="fas fa-user mr-2"></i>NAME
        </p>
        <div
          class="flex flex-col items-center text-center text-gray-600 dark:text-gray-400 font-weight-500"
        >
          <span class="break-all self-stretch">{{ storeOwner }}</span>
          <button
            class="text-green-700 mt-1 font-weight-500"
            (click)="editName()"
          >
            Edit <i class="fas fa-angle-right text-xs"></i>
          </button>
        </div>
      </div>
      <div class="flex flex-col items-center text-center">
        <p class="font-bold text-black dark:text-white mb-2 font-weight-700">
          <i class="fas fa-phone mr-2"></i>NUMBER
        </p>
        <div
          class="flex flex-col items-center text-center text-gray-600 dark:text-gray-400 font-weight-500"
        >
          <span class="break-all self-stretch">{{ storeData?.phone }}</span>
          <button
            class="text-green-700 mt-1 font-weight-500"
            (click)="editPhone()"
          >
            Edit <i class="fas fa-angle-right text-xs"></i>
          </button>
        </div>
      </div>
      <div class="flex flex-col items-center text-center">
        <p class="font-bold text-black dark:text-white mb-2 font-weight-700">
          <i class="fas fa-envelope mr-2"></i>EMAIL
        </p>
        <div
          class="flex flex-col items-center text-center text-gray-600 dark:text-gray-400 font-weight-500"
        >
          <span class="break-all self-stretch">{{
            storeData?.storesContact?.email
          }}</span>
          <button
            class="text-green-700 mt-1 font-weight-500"
            (click)="editEmail()"
          >
            Edit <i class="fas fa-angle-right text-xs"></i>
          </button>
        </div>
      </div>
    </div>

    <hr class="border-gray-300 dark:border-gray-600 my-4" />
    <div class="mt-4">
      <div class="flex justify-between items-center mb-2">
        <h3
          class="text-lg font-teko text-black dark:text-white font-bold flex items-center"
        >
          <span
            class="rounded-full w-6 h-6 flex items-center justify-center mr-2"
          >
            <i class="fas fa-note-sticky text-xs"></i>
          </span>
          PREVIOUS VISIT NOTES
        </h3>
        <div class="flex gap-4">
          <button
            class="text-green-700 bg-custom-green/20 px-3 py-1 rounded-md font-bold text-sm flex items-center gap-2 dark:text-white"
            (click)="viewNotes()"
          >
            <i class="fas fa-eye"></i>
            View Notes
          </button>
          <button
            class="text-green-700 bg-custom-green/20 px-3 py-1 rounded-md font-bold text-sm flex items-center gap-2 dark:text-white"
            (click)="this.addNote()"
          >
            <i class="fas fa-plus"></i>
            Add Note
          </button>
        </div>
      </div>
      <div *ngIf="latestNote" class="p-2 rounded-lg">
        <p class="text-sm font-bold text-gray-500 dark:text-white mb-1">
          {{ latestNote!.lastUpdated?.toDate() | date : "dd/MM/yyyy" }}
        </p>
        <p class="text-sm text-gray-500 font-weight-400 dark:text-white">
          {{ latestNote!.note }}
        </p>
      </div>
    </div>
  </div>
</div>

<app-bottom-nav-bar
  [leftButtonText]="'Back'"
  [rightButtonText]="'Start Call'"
  [leftButtonIcon]="'fa-solid fa-arrow-left'"
  [rightButtonIcon]="'fa-solid fa-arrow-right'"
  (leftButtonClickEvent)="goBack()"
  (rightButtonClickEvent)="startCall()"
>
</app-bottom-nav-bar>

<app-edit-store-modal
  [store]="this.storeData"
  [mode]="editMode"
  (storeUpdated)="this.storeUpdated()"
></app-edit-store-modal>
<app-add-store-note
  [storeId]="this.storeData?.storesId!"
  (storeUpdated)="this.storeUpdated()"
></app-add-store-note>

<app-view-notes-modal
  #viewNotesModal
  [storeNotes]="this.storeNotes"
></app-view-notes-modal>
