import { Component } from '@angular/core';
import {
  OrderResponse,
  SurveyStepResponse,
  SurveySubmissionService,
} from '../services/survey-submission.service';
import { Router } from '@angular/router';
import { ToastService } from '../_shared/services/toast.service';
@Component({
  selector: 'app-wholesale-page',
  templateUrl: './wholesale-page.component.html',
})
export class WholesalePageComponent {
  constructor(
    private surveySubmissionService: SurveySubmissionService,
    private router: Router,
    private toast: ToastService
  ) {}

  async submitWholesaleOrder(orderResponse: OrderResponse) {
    try {
      const surveyStepAnswers: SurveyStepResponse[] = [
        {
          questionGroupsId: 0, // Arbitrary ID
          questionGroupsName: 'Wholesale',
          responses: orderResponse,
        },
      ];

      await this.surveySubmissionService.submitSurvey(
        surveyStepAnswers,
        Number(localStorage.getItem('wholesaleStoresListId')),
        Number(localStorage.getItem('wholesaleStoreId')).toString(),
        0 //TODO: Change this to the actual survey ID
      );
      this.router.navigate(['/call-list']);
    } catch (error) {
      this.toast.error('Error submitting wholesale order');
    }
  }
}
