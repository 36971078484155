import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OrderedItem as ModalOrderedItem } from './order-selector-modal/order-selector-modal.component';
import { OrderSignatureComponent } from './order-signature/order-signature.component';
import { OrderReceiptComponent } from './order-receipt/order-receipt.component';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { ProductsModel, ProductsService } from 'src/app/services/swagger.gen';
import { OrderResponse } from '../../services/survey-submission.service';
import { ToastService } from 'src/app/_shared/services/toast.service';
import { SurveyStepResponse } from '../../services/survey-submission.service';
import { SurveySubmissionService } from '../../services/survey-submission.service';
import { PhotoUploadOutputModel } from '../components/photo-upload/photo-upload.component';

export interface Product {
  productName: string;
  skuName: string;
  imagePath: string;
  groupName: string;
  default: boolean;
}

interface SkuGroup {
  name: string;
  image: string;
}

@Component({
  selector: 'app-order-page',
  templateUrl: './order-page.component.html',
})
export class OrderPageComponent implements OnInit, OnDestroy {
  @Output() nextSurveyStepEvent = new EventEmitter<void>();
  @Output() previousSurveyStepEvent = new EventEmitter<void>();
  @Output() orderResponseChange = new EventEmitter<OrderResponse>();
  @Input() orderResponse: OrderResponse = {
    items: [],
    isWholesale: false,
  };

  products: ProductsModel[] = [];
  groupedProducts: ProductsModel[][] = [];

  showScrollButton = false;
  private scrollListener: any;

  selectedProduct?: ProductsModel[];
  showOrderModal = false;
  totalOrderCount = 0;
  totalOrderValue = 0;

  showOrderSummary = false;

  private modalOrderedItems: ModalOrderedItem[] = [];
  private receiptSnapshot: string = '';

  get orderItems(): ModalOrderedItem[] {
    return this.modalOrderedItems;
  }
  set orderItems(value: ModalOrderedItem[]) {
    this.modalOrderedItems = value;
    // Map modal items to service items
    this.orderResponse.items = value.map((item) => ({
      productId: item.product.productsId!,
      productName: item.product.fullProductName || '',
      price: item.price,
      quantity: item.quantity,
    }));
    this.orderResponseChange.emit(this.orderResponse);
  }

  @Input() isWholesale = false;

  pages: string[] = ['Order', 'Summary', 'Signature', 'Receipt'];
  wholesalePages: string[] = ['Order', 'Summary', 'PhotoReceipt', 'Receipt'];
  currentPage: number = 0;

  platformGroups: { [key: string]: string } = {
    '2': 'Additions',
    '3': 'Strategic Brands',
    '5': 'Monster',
    '6': 'Juiced',
    '7': 'Affordable',
    '8': 'Performance',
    '9': 'Ultra',
    '10': 'Platform',
    '11': 'Strategic',
    '12': 'Core',
    '13': 'Historic',
    '14': 'Juice',
    '15': 'Ultra Serbia',
    '16': 'Competition',
  };

  // This will store just the signature
  private signatureImage: string = '';

  get signatureData(): string {
    // For display in receipt, return the actual signature
    return this.signatureImage;
  }
  set signatureData(value: string) {
    this.signatureImage = value;
    // Only emit the receipt snapshot when it exists
    this.orderResponse.signature = this.receiptSnapshot || value;
    this.orderResponseChange.emit(this.orderResponse);
  }

  get wholesaleSignatureData(): string {
    return this.orderResponse.photoReceipt || '';
  }
  set wholesaleSignatureData(value: string) {
    this.orderResponse.photoReceipt = value;
    this.orderResponseChange.emit(this.orderResponse);
  }

  @ViewChild(OrderSignatureComponent)
  signatureComponent!: OrderSignatureComponent;

  @ViewChild(OrderReceiptComponent)
  receiptComponent!: OrderReceiptComponent;

  constructor(
    private http: HttpClient,
    private router: Router,
    private productsService: ProductsService,
    private toast: ToastService,
    private surveySubmissionService: SurveySubmissionService
  ) {}

  ngOnInit(): void {
    // Update isWholesale in orderResponse when it changes
    this.orderResponse.isWholesale = this.isWholesale;

    this.productsService
      .getProductsForCountry(localStorage.getItem('countryCode')!)
      .subscribe({
        next: (data) => {
          this.products = data;
          this.groupProducts();
        },
        error: (error) => {
          console.error('Error loading products:', error);
        },
      });

    // Add scroll listener
    this.scrollListener = () => {
      this.showScrollButton = window.scrollY > 100; // Show after 100px of scroll
    };
    window.addEventListener('scroll', this.scrollListener);

    if (this.isWholesale) {
      this.pages = this.wholesalePages;
    }

    // Initialize total order count from existing items
    this.totalOrderCount = this.orderResponse.items.length;
  }

  private getVariantType(
    productName: string
  ): 'Single' | 'Multipack' | 'Seeding' | 'Free' {
    if (productName.includes('Multipack')) return 'Multipack';
    if (productName.includes('Mulitpack')) return 'Multipack';
    if (productName.includes('500ml')) return 'Single';
    if (productName.includes('Free')) return 'Free';
    return 'Single';
  }

  ngOnDestroy(): void {
    window.removeEventListener('scroll', this.scrollListener);
  }

  private groupProducts(): void {
    const groupMap = new Map<string, ProductsModel[]>();

    this.products
      .filter((product) => product.packSize === 1)
      .forEach((product) => {
        if (product.logoFilename) {
          const groupName = product.logoFilename.split(/[/\\]/)[0];

          if (groupName === 'MONSTER') {
            // Use the platform dictionary to get the proper name and convert to uppercase
            const platformName = (
              this.platformGroups[product.platformsId?.toString() || 'Other'] ||
              'Other'
            ).toUpperCase();
            const platformGroup = `MONSTER ${platformName}`;
            const group = groupMap.get(platformGroup) || [];
            group.push(product);
            groupMap.set(platformGroup, group);
          } else {
            const group = groupMap.get(groupName) || [];
            group.push(product);
            groupMap.set(groupName, group);
          }
        }
      });

    // Sort each group by shortProductName and then by volume descending
    groupMap.forEach((group) => {
      group.sort((a, b) => {
        const nameCompare = (a.shortProductName || '').localeCompare(
          b.shortProductName || ''
        );
        if (nameCompare !== 0) return nameCompare;
        return (b.volume || 0) - (a.volume || 0);
      });
    });

    // Filter out groups based on variant availability
    const filteredGroupMap = new Map<string, ProductsModel[]>();
    groupMap.forEach((group, groupName) => {
      const filteredProducts = group.filter((product) => {
        const variants = this.products.filter(
          (p) =>
            p.shortProductName === product.shortProductName &&
            p.volume === product.volume
        );
        return this.isWholesale
          ? variants.some((variant) => variant.isWholesale)
          : variants;
      });

      if (filteredProducts.length > 0) {
        filteredGroupMap.set(groupName, filteredProducts);
      }
    });

    // Convert filtered map to arrays but sort Monster groups first
    const sortedGroups = Array.from(filteredGroupMap.entries()).sort((a, b) => {
      // If both are Monster groups, sort by platform name
      if (a[0].startsWith('MONSTER') && b[0].startsWith('MONSTER')) {
        return a[0].localeCompare(b[0]);
      }
      // If only a is Monster, it should come first
      if (a[0].startsWith('MONSTER')) return -1;
      // If only b is Monster, it should come first
      if (b[0].startsWith('MONSTER')) return 1;
      // For non-Monster groups, maintain alphabetical order
      return a[0].localeCompare(b[0]);
    });

    // Store only the values (product arrays) in groupedProducts
    this.groupedProducts = sortedGroups.map(([_, products]) => products);
  }

  scrollToGroup(group: SkuGroup) {
    const groupElement = document.getElementById(group.name);
    if (groupElement) {
      const headerOffset = 100;
      const elementPosition = groupElement.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.scrollY - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  }

  scrollToTop(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  openOrderModal(product: ProductsModel) {
    // Get all variants matching shortProductName and volume
    const variants = this.products.filter(
      (p) =>
        p.shortProductName === product.shortProductName &&
        p.volume === product.volume
    );

    // Filter variants based on wholesale/retail status
    const filteredVariants = variants.filter((variant) =>
      this.isWholesale ? variant.isWholesale : variant
    );

    // Add seeding variant for retail orders
    if (!this.isWholesale) {
      // Create a copy of the base product for seeding
      const seedingVariant = Object.assign(new ProductsModel(), {
        ...product,
        productsId: -1,
        fullProductName: `${product.fullProductName} Seeding x6`,
        packSize: 6,
      });
      filteredVariants.push(seedingVariant);
    }

    this.selectedProduct = filteredVariants;
    this.showOrderModal = true;
  }

  handleOrderConfirm(newItems: ModalOrderedItem[]) {
    // Handle order confirmation
    this.showOrderModal = false;

    //add, don't replace
    this.orderItems = [...this.orderItems, ...newItems];
    this.totalOrderCount = this.orderItems.length;
    this.totalOrderValue = this.orderItems.reduce(
      (acc, item) => acc + item.price * item.quantity,
      0
    );
  }

  async getSignature(): Promise<string> {
    return this.signatureComponent.getSignature();
  }

  handlePhotoReceiptChange(photos: PhotoUploadOutputModel) {
    this.wholesaleSignatureData = photos.photos[0] || '';
  }

  getTotal(): number {
    return this.totalOrderValue;
  }

  async nextStep() {
    if (
      this.pages[this.currentPage] === 'Order' ||
      this.pages[this.currentPage] === 'Summary'
    ) {
      if (this.totalOrderCount === 0) {
        Swal.fire({
          title: 'No items selected for order',
          text: !this.isWholesale
            ? 'Are you sure you want to continue to the next step of the survey?'
            : 'Go back to the store list?',
          icon: 'warning',
          confirmButtonText: '<i class="fas fa-check"></i> Confirm',
          cancelButtonText: 'Close',
          showCancelButton: true,
          customClass: {
            icon: 'no-border',
            confirmButton: 'btn btn-success bg-custom-green text-black',
            title: 'swal2-title-small',
          },
        }).then((result) => {
          if (result.isConfirmed) {
            if (!this.isWholesale) {
              this.nextSurveyStep();
            } else {
              this.router.navigate(['/call-list']);
            }
          }
        });
        return;
      }
    }

    if (this.pages[this.currentPage] === 'Signature') {
      // Just get and store the signature
      this.signatureData = await this.getSignature();
    }

    if (this.pages[this.currentPage] === 'Receipt' && !this.isWholesale) {
      // Capture the receipt snapshot when moving forward from receipt page
      try {
        this.receiptSnapshot = await this.receiptComponent.captureReceipt();
        // Update the order response with the receipt snapshot
        this.orderResponse.signature = this.receiptSnapshot;
        this.orderResponseChange.emit(this.orderResponse);
      } catch (error) {
        console.error('Failed to capture receipt:', error);
        this.toast.error('Failed to capture receipt');
        return;
      }
    }

    if (this.currentPage < this.pages.length - 1) {
      this.currentPage++;
    } else {
      if (!this.isWholesale) {
        this.nextSurveyStep();
      } else {
        this.submitWholesaleOrder();
      }
      return;
    }
  }

  submitWholesaleOrder() {
    if (this.orderResponse.items.length === 0) {
      this.toast.error('No items selected for order');
      return;
    }

    try {
      // Get the store ID and stores list ID from localStorage
      const storeId = localStorage.getItem('storeId') || '0';
      const storesListId = Number(localStorage.getItem('storesListId')) || 0;

      const surveyStepAnswers: SurveyStepResponse[] = [
        {
          questionGroupsId: 0, // Arbitrary ID for wholesale orders
          questionGroupsName: 'Wholesale',
          responses: this.orderResponse,
        },
      ];

      this.surveySubmissionService
        .submitSurvey(
          surveyStepAnswers,
          storesListId,
          storeId,
          1 // Arbitrary survey ID for wholesale orders
        )
        .then(() => {
          this.backToCallList();
        })
        .catch((error) => {
          this.toast.error('Error submitting wholesale order');
          console.error('Wholesale order submission error:', error);
        });
    } catch (error) {
      this.toast.error('Error submitting wholesale order');
      console.error('Wholesale order submission error:', error);
    }
  }

  previousStep() {
    if (this.currentPage > 0) {
      this.currentPage--;
    } else {
      if (!this.isWholesale) {
        this.previousSurveyStep();
      } else {
        this.router.navigate(['/call-list']);
      }
    }
  }

  backToCallList() {
    Swal.fire({
      title: 'Wholesale order complete',
      icon: 'success',
      confirmButtonText: '<i class="fas fa-check"></i> Back to call list',
      showCancelButton: true,
      customClass: {
        confirmButton: 'btn btn-success bg-custom-green text-black',
        title: 'swal2-title-small',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigate(['/call-list']);
      }
    });
  }

  nextSurveyStep() {
    this.nextSurveyStepEvent.emit();
  }

  previousSurveyStep() {
    this.previousSurveyStepEvent.emit();
  }

  handleOrderItemsChange(updatedItems: ModalOrderedItem[]) {
    this.orderItems = updatedItems;
    this.totalOrderCount = this.orderItems.length;
  }

  getProductGroup(logoFilename: string): string {
    if (!logoFilename) return '';
    const groupName = logoFilename.split(/[/\\]/)[0];

    if (groupName === 'MONSTER') {
      // Find the first product in the group to get its platformsId
      const product = this.products.find(
        (p) => p.logoFilename === logoFilename
      );
      if (product && product.platformsId) {
        // Use the platform dictionary to get the proper name
        const platformName = (
          this.platformGroups[product.platformsId.toString()] || 'Other'
        ).toUpperCase();
        return `MONSTER ${platformName}`;
      }
    }

    return groupName;
  }

  getImagePath(logoFilename: string): string {
    if (!logoFilename) return '';
    return '../assets/images/root-product/' + logoFilename.replace(/\\/g, '/');
  }
}
