<div class="form-group">
  <label
    [for]="id"
    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
    >{{ label }}</label
  >

  <div class="relative">
    <div
      [value]="selectedOptions"
      (click)="toggleDropdown()"
      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-custom-green focus:border-custom-green block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-custom-green dark:focus:border-custom-green cursor-pointer flex justify-between items-center"
    >
      <span>{{ getSelectionText() }}</span>
      <svg
        class="w-2.5 h-2.5 ms-3"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 10 6"
      >
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="m1 1 4 4 4-4"
        />
      </svg>
    </div>
    <div
      *ngIf="isOpen"
      class="absolute z-10 w-full mt-1 bg-white rounded-lg shadow-lg dark:bg-gray-700"
    >
      <div class="p-2 relative">
        <input
          type="text"
          [(ngModel)]="searchText"
          (input)="filterOptions()"
          placeholder="Search"
          class="w-full px-3 py-2 pr-8 text-sm bg-gray-50 border border-gray-300 rounded-lg focus:ring-custom-green focus:border-custom-green dark:bg-gray-600 dark:border-gray-500 dark:text-white"
        />
        <button
          *ngIf="searchText"
          (click)="clearSearch()"
          class="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
        >
          <svg
            class="w-4 h-4"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </button>
      </div>
      <div class="max-h-60 overflow-y-auto">
        <div
          *ngFor="let option of filteredOptions"
          (click)="toggleOption(option)"
          class="px-4 py-2 cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-600"
        >
          <div class="flex items-center">
            <input
              type="checkbox"
              [checked]="isSelected(option)"
              class="w-4 h-4 text-custom-green bg-gray-100 border-gray-300 rounded focus:ring-custom-green dark:focus:ring-custom-green dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            <label
              class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              {{ option.label }}
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
