import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import {
  QuestionOptionsUIModel,
  QuestionsUIModel,
} from 'src/app/services/swagger.gen';
import Swal from 'sweetalert2';
import { LocationResponse } from '../../../services/survey-submission.service';
import { LocationDiscontinueModalComponent } from './location-discontinue-modal.component';
import { SkuSelectorComponent } from '../sku-selector/sku-selector.component';
import { PhotoUploadOutputModel } from '../photo-upload/photo-upload.component';

@Component({
  selector: 'app-distribution-repeater',
  templateUrl: './distribution-repeater.component.html',
})
export class DistributionRepeaterComponent {
  @Input() id: string = '';
  @Input() name: string = '';
  @Input() label: string = '';
  @Input() questions: QuestionsUIModel[] = [];
  @Input() locationResponses!: LocationResponse[];

  @Output() nextSurveyStepEvent = new EventEmitter<void>();
  @Output() previousSurveyStepEvent = new EventEmitter<void>();

  @ViewChild(LocationDiscontinueModalComponent)
  locationDiscontinueModal!: LocationDiscontinueModalComponent;

  currentQuestion: QuestionsUIModel | null = null;
  distributionType: 'Entry' | 'Exit' = 'Entry';

  currentLocationResponse!: LocationResponse;

  page: number = -1;

  ngOnInit() {
    this.distributionType = this.name.toLowerCase().includes('entry')
      ? 'Entry'
      : 'Exit';

    this.resetCurrentLocationResponse();
  }

  private getNextPairId(): number {
    return (
      this.locationResponses.reduce(
        (maxId, loc) => Math.max(maxId, loc.pairId || 0),
        0
      ) + 1
    );
  }

  resetCurrentLocationResponse() {
    this.currentLocationResponse = {
      locationId: 0,
      photo: null,
      compliant: false,
      location: '',
      storeLocation: '',
      skus: [],
      completed: this.distributionType == 'Exit',
      createdAt: 'Entry',
      pairId: this.distributionType === 'Entry' ? this.getNextPairId() : 0,
      isExitLocation: this.distributionType === 'Exit',
    };
  }

  addLocation() {
    this.page = 0;
    this.currentQuestion = this.questions[0];
  }

  clickLocation(location: LocationResponse) {
    if (this.distributionType === 'Entry') {
      this.locationDiscontinueModal.locationName = location.location;
      this.locationDiscontinueModal.image = location.photo ?? '';
      this.locationDiscontinueModal.locationIndex =
        this.locationResponses.indexOf(location);
      this.locationDiscontinueModal.openModal();
    }
    if (this.distributionType === 'Exit') {
      // Create a deep copy of the location with independent SKUs array
      this.currentLocationResponse = {
        ...location,
        skus: location.skus.map((sku) => ({ ...sku })), // Deep copy each SKU
      };
      this.page = 0;
      this.currentQuestion = this.questions[0];
    }
  }

  locationDiscontinued(locationIndex: number) {
    this.locationDiscontinueModal.closeModal();
    this.locationResponses.splice(locationIndex, 1);
  }

  nextStep() {
    if (this.page === -1) {
      this.nextSurveyStep();
      return;
    }

    this.page++;
    if (this.page < this.questions.length) {
      this.currentQuestion = this.questions[this.page];
    } else {
      this.currentQuestion = null;

      if (this.distributionType === 'Entry') {
        const pairId = this.currentLocationResponse.pairId;
        // Create both Entry and Exit locations
        const entryLocation: LocationResponse = {
          ...this.currentLocationResponse,
          createdAt: 'Entry',
          completed: false,
          pairId,
          isExitLocation: false,
        };
        const exitLocation: LocationResponse = {
          ...this.currentLocationResponse,
          photo: this.currentLocationResponse.photo,
          createdAt: 'Entry',
          completed: false,
          pairId,
          isExitLocation: true,
          skus: this.currentLocationResponse.skus.map((sku) => ({ ...sku })),
        };

        // Add both locations to the array
        this.locationResponses.unshift(exitLocation);
        this.locationResponses.unshift(entryLocation);
      } else {
        if (this.currentLocationResponse.pairId === 0) {
          // This is a new Exit location, create just one location with pairId = -1
          const exitOnlyLocation: LocationResponse = {
            ...this.currentLocationResponse,
            createdAt: 'Entry',
            completed: true,
            pairId: -1,
            isExitLocation: true,
          };
          this.locationResponses.unshift(exitOnlyLocation);
        } else {
          // This is updating an existing location pair
          const exitIndex = this.locationResponses.findIndex(
            (loc) =>
              loc.pairId === this.currentLocationResponse.pairId &&
              loc.isExitLocation
          );

          const entryIndex = this.locationResponses.findIndex(
            (loc) =>
              loc.pairId === this.currentLocationResponse.pairId &&
              !loc.isExitLocation
          );

          if (exitIndex !== -1) {
            // Update Exit location
            this.locationResponses[exitIndex] = {
              ...this.currentLocationResponse,
              createdAt: 'Entry',
              completed: true,
              isExitLocation: true,
            };
          }

          if (entryIndex !== -1) {
            // Update Entry location to be completed
            this.locationResponses[entryIndex] = {
              ...this.locationResponses[entryIndex],
              completed: true,
            };
          }
        }
      }

      this.page = -1;
      this.resetCurrentLocationResponse();
    }
  }

  async nextSurveyStep() {
    // If locations is empty, show confirmation modal
    if (this.locationResponses.length === 0) {
      const result = await Swal.fire({
        title: 'No In Store Locations Added',
        text: 'Are you sure you want to proceed?',
        icon: 'warning',
        confirmButtonText: '<i class="fas fa-check"></i> Confirm',
        showCancelButton: true,
        cancelButtonText: 'Close',
        customClass: {
          icon: 'no-border',
          confirmButton: 'btn btn-success bg-custom-green text-black',
          title: 'swal2-title-small',
        },
      });

      if (!result.isConfirmed) {
        return;
      }
    }

    if (this.distributionType === 'Exit') {
      if (!this.locationResponses.every((location) => location.completed)) {
        const result = await Swal.fire({
          title: 'Incomplete Locations',
          text: 'Are you sure you want to proceed?',
          icon: 'warning',
          confirmButtonText: '<i class="fas fa-check"></i> Confirm',
          showCancelButton: true,
          cancelButtonText: 'Close',
          customClass: {
            icon: 'no-border',
            confirmButton: 'btn btn-success bg-custom-green text-black',
            title: 'swal2-title-small',
          },
        });

        if (!result.isConfirmed) {
          return;
        }
      }
    }

    this.nextSurveyStepEvent.emit();
  }

  photoUploaded(photos: PhotoUploadOutputModel) {
    this.currentLocationResponse.photo = photos.photos[0];
  }

  previousStep() {
    if (this.page === -1) {
      this.previousSurveyStepEvent.emit();
      return;
    }

    this.page--;
    if (this.page == -1) {
      this.resetCurrentLocationResponse();
    }
    this.currentQuestion = this.questions[this.page];
  }

  shouldShowLocation(location: LocationResponse): boolean {
    // Check if this location belongs in this step
    const isEntryStep = this.name.toLowerCase().includes('entry');
    const isExitStep = this.name.toLowerCase().includes('exit');

    // Only show Entry locations in Entry step and Exit locations in Exit step
    if (isEntryStep && location.isExitLocation) return false;
    if (isExitStep && !location.isExitLocation) return false;

    // Then apply the normal distribution type checks
    if (this.distributionType === 'Entry') {
      return location.isExitLocation !== true;
    } else {
      return location.isExitLocation === true;
    }
  }
}
