import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SwalComponent, SwalPortalTargets } from '@sweetalert2/ngx-sweetalert2';
import { StoresListUIModel } from 'src/app/services/swagger.gen';

@Component({
  selector: 'app-store-checker-modal',
  templateUrl: './store-checker-modal.component.html',
})
export class StoreCheckerModalComponent {
  @ViewChild('modalContent')
  private modalContent!: SwalComponent;

  // emit storeListId and storeId
  @Output() claimStoreEvent = new EventEmitter<[number, number]>();

  stores: StoresListUIModel[] = [];
  currentTDMId: number = 0;
  constructor(
    public readonly swalTargets: SwalPortalTargets,
    private router: Router
  ) {}

  open(stores: StoresListUIModel[]) {
    this.stores = stores;
    this.modalContent.fire();
  }

  close() {
    this.modalContent.close();
  }

  goToSummary(
    storeListId: number | undefined,
    storeId: number | undefined,
    tdmId: number | undefined
  ) {
    if (tdmId === this.currentTDMId) {
      this.router.navigate(['/store-summary', storeId]);
    } else {
      this.claimStore(storeListId!, storeId!);
    }
  }

  claimStore(storeListId: number, storeId: number) {
    this.claimStoreEvent.emit([storeListId, storeId]);
    this.close();
  }
}
