import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class BugsClickupApiService {
  private apiUrl = 'https://api.clickup.com/api/v2/list/901107232206/task';
  private authKey = 'pk_38634445_OBGHXO4H2JJOW6VNGS6CXL8OORO88718';

  constructor(private http: HttpClient) { }

  createTask(bugForm: any): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': this.authKey,
      'Content-Type': 'application/json'
    });

    return this.http.post<any>(this.apiUrl, JSON.stringify(bugForm), { headers });
  }
}
