import { Component, Input, ViewChild } from '@angular/core';
import { StoreNotesModel } from '../../services/swagger.gen';
import { SwalComponent, SwalPortalTargets } from '@sweetalert2/ngx-sweetalert2';

@Component({
  selector: 'app-view-notes-modal',
  templateUrl: './view-notes-modal.component.html',
})
export class ViewNotesModalComponent {
  @Input() storeNotes: StoreNotesModel[] = [];

  constructor(public swalTargets: SwalPortalTargets) {}

  @ViewChild('modalContent') modalContent!: SwalComponent;

  get sortedNotes() {
    return [...this.storeNotes].sort(
      (a, b) =>
        new Date(b.lastUpdated!.toDate()).getTime() -
        new Date(a.lastUpdated!.toDate()).getTime()
    );
  }

  formatDate(date: string) {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  }

  open() {
    this.modalContent.fire();
  }

  close() {
    this.modalContent.close();
  }
}
