<app-loading-spinner [isLoading]="isLoading"></app-loading-spinner>
<section
  *ngIf="!isLoading"
  class="bg-gray-50 h-full flex flex-col dark:bg-gray-900 pb-16 md:pb-0"
>
  <div
    class="flex-grow mx-auto max-w-screen-xl p-2 h-full -z-1 overflow-y-auto"
  >
    <div
      class="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden border border-gray-300 dark:border-gray-600 mb-16 md:mb-0"
    >
      <div class="flex-grow flex justify-center pt-8">
        <h2
          class="text-5xl font-teko font-bold text-black dark:text-white uppercase"
        >
          STORE LIST
        </h2>
      </div>
      <div class="flex justify-between items-center p-4">
        <div class="relative w-1/3">
          <div
            class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
          >
            <i class="fa-solid fa-search text-gray-400 dark:text-gray-300"></i>
          </div>
          <input
            type="text"
            id="simple-search"
            [(ngModel)]="searchText"
            (ngModelChange)="onSearch($event)"
            name="search"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-custom-green focus:border-custom-green block w-full pl-10 pr-10 py-2 h-[38px] dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-custom-green dark:focus:border-custom-green"
            placeholder="Search call list"
            required
          />
          <button
            *ngIf="searchText"
            (click)="clearSearch()"
            class="absolute inset-y-0 right-0 flex items-center pr-3"
          >
            <i
              class="fa-solid fa-times text-gray-400 dark:text-gray-300 hover:text-gray-500 dark:hover:text-gray-200"
            ></i>
          </button>
        </div>
        <div class="w-1/3 flex justify-end">
          <button
            type="button"
            class="disabled flex items-center justify-center text-black bg-custom-green font-medium rounded-lg text-sm px-4 py-2 h-[38px] dark:bg-custom-green dark:hover:bg-custom-green-dark focus:outline-none dark:focus:ring-custom-green-light"
            (click)="addNewStore()"
          >
            <i class="fa-solid fa-plus mr-2"></i>
            Add new store
          </button>
        </div>
        <!-- <div *ngIf="this.showTdms" class="w-1/3 flex justify-end">
          <label class="block text-sm font-proxima mb-1 dark:text-white"
            >TDM Select:</label
          >
          <select
            class="border rounded-md px-3 py-2 font-proxima w-full text-sm bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:ring-2 focus:ring-custom-green focus:border-custom-green transition duration-150 ease-in-out"
            [(ngModel)]="selectedTdm"
            (change)="onTDMChange(selectedTdm)"
          >
            <option
              *ngFor="let tdm of tdmList"
              [ngValue]="tdm.tradeDevelopmentManagersId"
            >
              {{ tdm.salutation + " " + tdm.firstname + " " + tdm.surname }}
            </option>
          </select>
        </div> -->
      </div>
      <hr class="border-t dark:border-gray-700 mx-4 mb-2" />

      <!-- Filter section -->
      <div class="flex items-center space-x-4 mb-2 ml-4">
        <span class="text-black dark:text-white font-proxima">Filters</span>

        <div *ngFor="let filter of filters" class="flex items-center">
          <input
            [id]="'filter-' + filter.filter"
            type="radio"
            [value]="filter.filter"
            name="filter-radio"
            [checked]="activeFilter === filter.filter"
            (change)="toggleFilter(filter.filter)"
            class="w-4 h-4 text-custom-green bg-gray-100 border-gray-300 dark:bg-gray-700 dark:border-gray-600 focus:ring-1 focus:ring-custom-green focus:ring-offset-0"
          />
          <label
            [for]="'filter-' + filter.filter"
            class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300 cursor-pointer"
          >
            {{ filter.label }}
          </label>
        </div>
      </div>

      <div class="overflow-x-auto">
        <table
          class="w-full text-sm text-left text-gray-500 dark:text-gray-400 table-fixed"
        >
          <thead
            class="text-xs uppercase font-teko text-gray-700 bg-gray-50 dark:bg-gray-700 dark:text-gray-400 border-b border-gray-300 dark:border-gray-600"
          >
            <tr>
              <th
                scope="col"
                class="w-[35%] px-3 py-4"
                (click)="sortTable('storeName')"
              >
                Store Name
                <i
                  [ngClass]="[
                    'ml-1',
                    'storeName' | sortIcon : sortColumn : sortDirection
                  ]"
                ></i>
              </th>
              <th
                scope="col"
                class="text-center w-[12%]"
                (click)="sortTable('lastVisit')"
              >
                Last Visit
                <i
                  [ngClass]="[
                    'ml-1',
                    'lastVisit' | sortIcon : sortColumn : sortDirection
                  ]"
                ></i>
              </th>
              <th
                scope="col"
                class="text-center w-[12%]"
                (click)="sortTable('postcode')"
              >
                Post Code
                <i
                  [ngClass]="[
                    'ml-1',
                    'postcode' | sortIcon : sortColumn : sortDirection
                  ]"
                ></i>
              </th>
              <th
                scope="col"
                class="text-center w-[11%]"
                (click)="sortTable('isFavourite')"
              >
                Favourite
                <i
                  [ngClass]="[
                    'ml-1',
                    'isFavourite' | sortIcon : sortColumn : sortDirection
                  ]"
                ></i>
              </th>
              <th
                scope="col"
                class="text-center w-[15%]"
                (click)="sortTable('status')"
              >
                Status
                <i
                  [ngClass]="[
                    'ml-1',
                    'status' | sortIcon : sortColumn : sortDirection
                  ]"
                ></i>
              </th>
              <th scope="col" class="text-center w-[10%]">Remove</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let store of storeCallListVisible"
              class="border-b dark:border-gray-700"
            >
              <td
                class="px-3 py-3 font-proxima font-medium text-gray-900 dark:text-white text-base truncate"
              >
                <div
                  (click)="storeClick(store.storesListId)"
                  class="truncate cursor-pointer underline decoration-1 underline-offset-2"
                  [title]="store.storeName"
                >
                  {{ store.storeName }}
                </div>
              </td>
              <td class="text-center">
                {{ store.lastVisit | date : "dd/MM/yyyy" }}
              </td>
              <td class="text-center">
                {{ store.postcode | postcode }}
              </td>
              <td class="text-center text-lg">
                <i
                  *ngIf="store.isFavourite"
                  class="fa-solid fa-star text-yellow-400 cursor-pointer"
                  (click)="unfavouriteStore(store.storesListId)"
                ></i>
                <i
                  *ngIf="!store.isFavourite"
                  class="fa-regular fa-star text-gray-300 cursor-pointer"
                  (click)="favouriteStore(store.storesListId)"
                ></i>
              </td>
              <td class="text-center">
                <span
                  [ngClass]="{
                    'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-300':
                      store.status === storeCallStatus.Completed,
                    'bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-300':
                      store.status === storeCallStatus.Uncompleted
                  }"
                  class="font-proxima text-xs font-medium px-2.5 py-0.5 rounded"
                >
                  {{
                    store.status === storeCallStatus.Completed
                      ? "Completed"
                      : "Uncompleted"
                  }}
                </span>
              </td>
              <td class="text-center">
                <button
                  (click)="toggleRemoveStore(store.storesListId)"
                  class="text-red-600 dark:text-red-500"
                >
                  <i
                    [ngClass]="store.storesListId | removeIcon : removedStores"
                  ></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <nav
        class="flex justify-between items-center p-4"
        aria-label="Table navigation"
      >
        <span class="text-sm font-proxima text-gray-500 dark:text-gray-400">
          Showing
          <span class="font-bold font-teko">{{
            (currentPage - 1) * pageSize + 1
          }}</span>
          -
          <span class="font-bold font-teko">{{
            Math.min(currentPage * pageSize, totalStores)
          }}</span>
          of
          <span class="font-bold font-teko">{{ totalStores }}</span>
        </span>
        <div
          class="h-8 rounded border border-gray-300 dark:border-gray-600 justify-start items-center inline-flex font-proxima overflow-hidden"
        >
          <button
            [disabled]="currentPage === 1"
            (click)="onPageChange(currentPage - 1)"
            class="self-stretch px-3 py-1.5 bg-white dark:bg-gray-800 flex-col justify-center items-center inline-flex border-r border-gray-300 dark:border-gray-600"
          >
            <i
              class="fas fa-chevron-left text-xs text-gray-500 dark:text-gray-400"
            ></i>
          </button>
          <ng-container *ngFor="let page of getPagesArray()">
            <div
              *ngIf="page !== '...'"
              (click)="onPageChange(page)"
              [ngClass]="{ 'active-page': page === currentPage }"
              class="self-stretch px-3 py-1.5 bg-white dark:bg-gray-800 border-r border-gray-300 dark:border-gray-600 flex-col justify-center items-center inline-flex cursor-pointer"
            >
              <div
                [class.text-[#2f4314]]="page === currentPage"
                [class.dark:text-[#eff7e3]]="page === currentPage"
                class="text-center text-gray-500 dark:text-gray-400 text-sm font-medium leading-[21px]"
              >
                {{ page }}
              </div>
            </div>
            <div
              *ngIf="page === '...'"
              class="self-stretch px-3 py-1.5 bg-white dark:bg-gray-800 border-r border-gray-300 dark:border-gray-600 flex-col justify-center items-center inline-flex"
            >
              <div
                class="text-center text-gray-500 dark:text-gray-400 text-sm font-medium leading-[21px]"
              >
                ...
              </div>
            </div>
          </ng-container>
          <button
            [disabled]="currentPage === totalPages"
            (click)="onPageChange(currentPage + 1)"
            class="self-stretch px-3 py-1.5 bg-white dark:bg-gray-800 flex-col justify-center items-center inline-flex"
          >
            <i
              class="fas fa-chevron-right text-xs text-gray-500 dark:text-gray-400"
            ></i>
          </button>
        </div>
      </nav>
    </div>
  </div>
</section>
