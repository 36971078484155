import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CallListComponent } from './call-list/call-list.component';
import { StoreSummaryComponent } from './store-summary/store-summary.component';
import { SurveyPageComponent } from './survey/survey-page/survey-page.component';
import { MsalGuard } from '@azure/msal-angular';
import { EndStoreVisitComponent } from './survey/end-store-visit/end-store-visit.component';
import { WholesalePageComponent } from './wholesale-page/wholesale-page.component';
import { FeedbackPageComponent } from './feedback-page/feedback-page.component';
import { AddNewStoreComponent } from './add-new-store/add-new-store.component';
import { LoginComponent } from './login/login.component';
import { DayLandingPageComponent } from './day-landing-page/day-landing-page.component';

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  {
    path: 'call-list',
    component: CallListComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'store-summary/:id',
    component: StoreSummaryComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'survey/:storeId',
    component: SurveyPageComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'end-store-visit/:storeId',
    component: EndStoreVisitComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'wholesale',
    component: WholesalePageComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'feedback',
    component: FeedbackPageComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'add-new-store',
    component: AddNewStoreComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'day-landing',
    component: DayLandingPageComponent,
    canActivate: [MsalGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
