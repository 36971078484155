import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { IdentityService } from './identity.service';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs/internal/Observable';

declare var AuthenticationContext: any;

@Injectable()
export class RouteGuard implements CanActivate {

    authContext = new AuthenticationContext(environment.AD);

    constructor(private router: Router, private identityService: IdentityService) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        // this.authContext.logOut();
        return true;


    }
}
