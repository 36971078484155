import { Component, Input, ViewChild, ElementRef } from '@angular/core';
import { OrderedItem } from '../order-selector-modal/order-selector-modal.component';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-order-receipt',
  templateUrl: './order-receipt.component.html',
})
export class OrderReceiptComponent {
  @Input() orderItems: OrderedItem[] = [];
  @Input() photoData: string = '';
  @Input() isWholesale: boolean = false;
  @Input() total: number = 0;
  @ViewChild('receiptContent') receiptContent!: ElementRef;

  getImagePath(logoFilename: string): string {
    if (!logoFilename) return '';
    return '../assets/images/root-product/' + logoFilename.replace(/\\/g, '/');
  }

  async captureReceipt(): Promise<string> {
    const element = this.receiptContent.nativeElement;

    try {
      const canvas = await html2canvas(element, {
        scale: 2, // Higher resolution
        useCORS: true, // Enable if you have cross-origin images
        backgroundColor: '#ffffff', // White background
        logging: false,
      });

      return canvas.toDataURL('image/png');
    } catch (error) {
      console.error('Error capturing receipt:', error);
      throw error;
    }
  }
}
