<app-loading-spinner [isLoading]="loading"></app-loading-spinner>
<div *ngIf="!loading" class="container mx-auto p-2">
  <div
    class="flex flex-col bg-white dark:bg-gray-800 rounded-lg shadow-md p-4 mt-3 gap-4 pb-40"
  >
    <div class="flex flex-col items-center justify-center gap-4 p-6">
      <div
        class="font-teko text-black dark:text-white uppercase text-5xl font-bold"
      >
        Add New Store
      </div>
      <div class="font-proxima text-black dark:text-white text-xl">
        This is where you will add a new store to store list
      </div>
    </div>
    <div
      class="uppercase font-teko text-black dark:text-white text-2xl font-bold leading-9 mb-4"
    >
      New store information
    </div>
    <form
      (ngSubmit)="onSubmit()"
      #updateForm="ngForm"
      class="grid grid-cols-2 gap-4 mb-4"
    >
      <div class="col-span-1">
        <label
          for="storeName"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >Store Name</label
        >
        <input
          type="text"
          id="storeName"
          name="storeName"
          [(ngModel)]="store!.storeName"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-custom-green focus:border-custom-green block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-custom-green dark:focus:border-custom-green"
        />
      </div>
      <div class="col-span-1">
        <label
          for="salutation"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >Title</label
        >
        <input
          type="text"
          id="salutation"
          name="salutation"
          [(ngModel)]="store!.salutation"
          placeholder="Mr, Mrs, Ms, Dr, etc."
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-custom-green focus:border-custom-green block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-custom-green dark:focus:border-custom-green"
        />
      </div>
      <div class="col-span-1">
        <label
          for="firstName"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >First Name</label
        >
        <input
          type="text"
          id="firstName"
          name="firstName"
          [(ngModel)]="store!.firstName"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-custom-green focus:border-custom-green block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-custom-green dark:focus:border-custom-green"
        />
      </div>
      <div class="col-span-1">
        <label
          for="surname"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >Surname</label
        >
        <input
          type="text"
          id="surname"
          name="surname"
          [(ngModel)]="store!.surname"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-custom-green focus:border-custom-green block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-custom-green dark:focus:border-custom-green"
        />
      </div>
      <div class="col-span-1">
        <label
          for="jobTitle"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >Job Title</label
        >
        <input
          type="text"
          id="jobTitle"
          name="jobTitle"
          placeholder="Manager, Owner, etc."
          [(ngModel)]="store!.jobTitle"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-custom-green focus:border-custom-green block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-custom-green dark:focus:border-custom-green"
        />
      </div>
      <div class="col-span-1">
        <label
          for="telephone"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >Telephone Number</label
        >
        <input
          type="tel"
          id="telephone"
          name="telephone"
          [(ngModel)]="store!.phone"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-custom-green focus:border-custom-green block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-custom-green dark:focus:border-custom-green"
        />
      </div>
      <div class="col-span-1">
        <label
          for="email"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >Email</label
        >
        <input
          type="email"
          id="email"
          name="email"
          [(ngModel)]="store!.email"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-custom-green focus:border-custom-green block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-custom-green dark:focus:border-custom-green"
        />
      </div>
      <div class="col-span-1">
        <label
          for="address1"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >Address 1</label
        >
        <input
          type="text"
          id="address1"
          name="address1"
          [(ngModel)]="store!.address1"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-custom-green focus:border-custom-green block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-custom-green dark:focus:border-custom-green"
        />
      </div>
      <div class="col-span-1">
        <label
          for="address2"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >Address 2</label
        >
        <input
          type="text"
          id="address2"
          name="address2"
          [(ngModel)]="store!.address2"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-custom-green focus:border-custom-green block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-custom-green dark:focus:border-custom-green"
        />
      </div>
      <div class="col-span-1">
        <label
          for="address3"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >Address 3</label
        >
        <input
          type="text"
          id="address3"
          name="address3"
          [(ngModel)]="store!.address3"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-custom-green focus:border-custom-green block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-custom-green dark:focus:border-custom-green"
        />
      </div>
      <div class="col-span-1">
        <label
          for="address4"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >Address 4</label
        >
        <input
          type="text"
          id="address4"
          name="address4"
          [(ngModel)]="store!.address4"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-custom-green focus:border-custom-green block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-custom-green dark:focus:border-custom-green"
        />
      </div>
      <div class="col-span-1">
        <label
          for="address5"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >Address 5</label
        >
        <input
          type="text"
          id="address5"
          name="address5"
          [(ngModel)]="store!.address5"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-custom-green focus:border-custom-green block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-custom-green dark:focus:border-custom-green"
        />
      </div>
      <div class="col-span-1">
        <label
          for="postCode"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >Post Code</label
        >
        <input
          type="text"
          id="postCode"
          name="postCode"
          [(ngModel)]="store!.postcode"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-custom-green focus:border-custom-green block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-custom-green dark:focus:border-custom-green"
        />
      </div>
      <div class="col-span-1">
        <label
          for="bottlerStatus"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-start"
          >Bottler Status</label
        >
        <select
          id="bottlerStatus"
          name="bottlerStatus"
          [(ngModel)]="store!.managementStatus"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-custom-green focus:border-custom-green block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-custom-green dark:focus:border-custom-green"
        >
          <option [ngValue]="1">Unknown</option>
          <option [ngValue]="2">Managed By Monster</option>
          <option [ngValue]="3">Managed By Bottler</option>
        </select>
      </div>
    </form>
  </div>
</div>

<app-bottom-nav-bar
  [leftButtonIcon]="'fa-solid fa-arrow-left'"
  [leftButtonText]="'Back'"
  (leftButtonClickEvent)="backToCallList()"
  [rightButtonIcon]="'fa-solid fa-check'"
  [rightButtonText]="'Add new store'"
  (rightButtonClickEvent)="onSubmit()"
  *ngIf="!loading"
></app-bottom-nav-bar>

<app-store-checker-modal
  #storeCheckerModal
  (claimStoreEvent)="claimStore($event[0], $event[1])"
></app-store-checker-modal>
