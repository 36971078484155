<div
  class="w-full h-20 bg-white p-6 border-b border-gray-200 text-black dark:bg-zinc-900 dark:border-zinc-700 flex justify-between fixed top-0 z-50"
>
  <div class="flex items-center">
    <div class="mr-4">
      <img src="assets/clawstore-vert-logo.png" alt="logo" class="w-12" />
    </div>
    <!-- <div class="mr-4">
            <button (click)="leftSidebar = true">
                <svg class="w-6 h-6 text-gray-800 dark:text-zinc-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" stroke-linecap="round" stroke-width="2" d="M5 7h14M5 12h14M5 17h14"/>
                </svg>
            </button>
        </div>              
        <div class="relative">
            <input type="text" class="h-10 w-52 md:w-96 lg:w-96 pl-10 pr-4 border border-gray-300 rounded-md text-sm placeholder-gray-500 dark:bg-neutral-700 dark:border-zinc-700 dark:text-white" placeholder="Search" />
            <div class="absolute left-2 top-2">
                <svg class="w-6 h-6 text-gray-400 dark:text-zinc-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" stroke-linecap="round" stroke-width="2" d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"/>
                </svg>     
            </div>
        </div> -->
  </div>
  <div class="flex items-center justify-end">
    <button
      (click)="toggleDarkMode()"
      class="flex items-center justify-center p-2 h-10"
    >
      <!-- Sun icon for dark mode -->
      <i
        *ngIf="darkMode"
        class="fas fa-sun text-2xl text-gray-400 dark:text-gray-400"
      ></i>
      <!-- Moon icon for light mode -->
      <i
        *ngIf="!darkMode"
        class="fas fa-moon text-2xl text-gray-400 dark:text-gray-400"
      ></i>
    </button>

    <!-- Commented notification button -->
    <!-- <button (click)="notificationSidebar = true" class="flex items-center justify-center h-10">
      <i class="fas fa-bell text-2xl text-gray-400 dark:text-gray-400"></i>
    </button> -->

    <div class="relative">
      <img
        [src]="profileImageUrl"
        (click)="toggleDropdown()"
        class="hidden md:block lg:block xl:block rounded-full w-10 h-10 ml-4 border border-black cursor-pointer"
      />

      <!-- Dropdown Menu -->
      <div
        *ngIf="showDropdown"
        class="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-50"
      >
        <button
          (click)="logout()"
          class="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 font-teko"
        >
          <i class="fas fa-sign-out-alt text-gray-400 mr-2"></i>
          Logout
        </button>
      </div>
    </div>
  </div>
</div>
<!-- <app-leftsidebar
  [leftSidebar]="leftSidebar"
  (closeModalEvent)="leftSidebar = false"
></app-leftsidebar>
<app-notifications
  [notificationSidebar]="notificationSidebar"
  (closeModalEvent)="notificationSidebar = false"
></app-notifications> -->
