import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { ToastService } from 'src/app/_shared/services/toast.service';
import {
  StoreNotesInputModel,
  StoresSummaryService,
} from 'src/app/services/swagger.gen';

@Component({
  selector: 'app-end-store-visit',
  templateUrl: './end-store-visit.component.html',
})
export class EndStoreVisitComponent implements OnInit {
  private destroy$ = new Subject<void>();

  storeId: string = '';
  note: string = '';

  constructor(
    private router: Router,
    private toastService: ToastService,
    private route: ActivatedRoute,
    private storesService: StoresSummaryService
  ) {}

  ngOnInit() {
    this.route.paramMap.subscribe((params) => {
      this.storeId = params.get('storeId')!;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  finish() {
    if (this.note && this.note.trim()) {
      let storeNote = new StoreNotesInputModel();
      storeNote.note = this.note.trim();
      storeNote.storeId = +this.storeId;

      this.storesService
        .addStoreNote(storeNote)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: () => {
            setTimeout(() => {
              this.toastService.success('Store Note Added Successfully.');
              this.router.navigate(['call-list/']);
            }, 100);
          },
          error: () => {
            this.toastService.error('Error adding store note');
          },
        });
    } else {
      this.router.navigate(['call-list/']);
    }
  }
}
