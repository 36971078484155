import {
  Component,
  Input,
  Output,
  EventEmitter,
  forwardRef,
  OnInit,
  OnDestroy,
  ElementRef,
  HostListener,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

interface Option {
  value: string;
  label: string;
}

@Component({
  selector: 'app-dropdown-multi',
  templateUrl: './dropdown-multi.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownMultiComponent),
      multi: true,
    },
  ],
})
export class DropdownMultiComponent implements ControlValueAccessor, OnInit {
  @Input() id: string = 'testMultiSelect';
  @Input() name: string = 'testMultiSelect';
  @Input() label: string = 'Test Multi-Select Dropdown';
  @Input() options: Option[] = [];
  @Input() set value(val: string | number | string[]) {
    if (Array.isArray(val)) {
      this.selectedOptions = [...val];
      this.onChange(this.selectedOptions);
    }
  }

  @Output() valueChange = new EventEmitter<string[]>();

  isOpen: boolean = false;
  searchText: string = '';
  filteredOptions: Option[] = [];
  selectedOptions: string[] = [];

  private onChange: (value: string[]) => void = () => {};
  private onTouched: () => void = () => {};

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    this.filteredOptions = [...this.options];
    if (!this.selectedOptions) {
      this.selectedOptions = [];
    }
  }

  @HostListener('document:click', ['$event'])
  clickOutside(event: Event) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isOpen = false;
    }
  }

  getSelectionText(): string {
    const selectedCount = this.selectedOptions.length;
    if (selectedCount === 0) {
      return 'Select options';
    } else {
      return `${selectedCount} selected`;
    }
  }

  toggleDropdown() {
    this.isOpen = !this.isOpen;
    if (this.isOpen) {
      this.onTouched();
    }
  }

  filterOptions(): void {
    this.filteredOptions = this.options.filter((option) =>
      option.label.toLowerCase().includes(this.searchText.toLowerCase())
    );
  }

  toggleOption(option: Option) {
    const currentSelections = [...this.selectedOptions];
    const index = currentSelections.indexOf(option.value);

    if (index > -1) {
      currentSelections.splice(index, 1);
    } else {
      currentSelections.push(option.value);
    }

    this.selectedOptions = currentSelections;
    this.value = this.selectedOptions;
    this.onChange(this.selectedOptions);
    this.valueChange.emit(this.selectedOptions);
  }

  isSelected(option: Option): boolean {
    return this.selectedOptions.includes(option.value);
  }

  writeValue(value: string[]): void {
    this.selectedOptions = value ? [...value] : [];
    this.valueChange.emit(this.selectedOptions);
  }

  registerOnChange(fn: (value: string[]) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  clearSearch(): void {
    this.searchText = '';
    this.filterOptions();
  }
}
