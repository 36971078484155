import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  UpdateStoreInputModel,
  StoresSummaryService,
  StoresListStatusEnum,
  StoreManagementStatusEnum,
} from '../services/swagger.gen';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { StoreCheckerModalComponent } from './store-checker-modal/store-checker-modal.component';
import { ToastService } from '../_shared/services/toast.service';

@Component({
  selector: 'app-add-new-store',
  templateUrl: './add-new-store.component.html',
  styleUrls: ['./add-new-store.component.css'],
})
export class AddNewStoreComponent implements OnInit, OnDestroy {
  $destroy = new Subject<void>();

  store!: UpdateStoreInputModel;

  loading: boolean = false;

  @ViewChild('storeCheckerModal')
  storeCheckerModal!: StoreCheckerModalComponent;

  constructor(
    private storeService: StoresSummaryService,
    private router: Router,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.store = new UpdateStoreInputModel();
    this.store.storeName = '';
    this.store.address1 = '';
    this.store.address2 = '';
    this.store.address3 = '';
    this.store.address4 = '';
    this.store.address5 = '';
    this.store.postcode = '';
    this.store.countryName = '';
    this.store.countryCode = localStorage.getItem('countryCode') || '';
    this.store.salutation = '';
    this.store.firstName = '';
    this.store.surname = '';
    this.store.jobTitle = '';
    this.store.phone = '';
    this.store.email = '';
    this.store.active = StoresListStatusEnum._1;
    this.store.managementStatus = StoreManagementStatusEnum._0;
    this.store.tdmId = Number(localStorage.getItem('tdmId')!);
    this.store.rfmId = Number(localStorage.getItem('rfmId')!);
  }

  // showing the enum values until we work out how to get the enum values from nSwag
  //   public enum StoreManagementStatusEnum
  // {
  //     None = 0,
  //     Unknown = 1,
  //     ManagedByMonster = 2,
  //     ManagedByBottler = 3
  // }

  // public enum StoresListStatusEnum
  // {
  //     None = 0,
  //     Active,
  //     PendingAddToListConfirmation,
  //     PendingDeleteFromListConfirmation
  // }

  ngOnDestroy() {
    this.$destroy.next();
    this.$destroy.complete();
  }

  onSubmit() {
    this.loading = true;
    this.storeService
      .checkStoreExists(this.store)
      .pipe(takeUntil(this.$destroy))
      .subscribe({
        next: (res) => {
          if (res === null || res === undefined || res.length === 0) {
            this.createStore();
            this.loading = false;
          } else {
            this.loading = false;
            this.storeCheckerModal.open(res);
          }
        },
        error: () => {
          console.log('Error creating store');
          this.loading = false;
        },
      });
  }

  createStore() {
    this.storeService
      .addStore(this.store)
      .pipe(takeUntil(this.$destroy))
      .subscribe({
        next: (res) => {
          this.toastService.success('Store created');
          this.router.navigate(['/store-summary', res]);
        },
        error: () => {
          this.toastService.error('Error creating store');
        },
      });
  }

  claimStore(storeListId: number, storeId: number) {
    this.loading = true;

    let claimStore = new UpdateStoreInputModel();
    claimStore.storesListId = storeListId;
    claimStore.tdmId = this.store.tdmId;
    claimStore.rfmId = this.store.rfmId;

    this.storeService
      .claimStore(claimStore)
      .pipe(takeUntil(this.$destroy))
      .subscribe({
        next: (res) => {
          this.toastService.success('Store claimed');
          this.router.navigate(['/store-summary', storeId]);
        },
        error: () => {
          this.toastService.error('Error claiming store');
          this.loading = false;
        },
      });
  }

  backToCallList() {
    this.router.navigate(['/call-list']);
  }
}
